import React from 'react';
import Grid from '@material-ui/core/Grid';

class CoursePayComponent extends React.Component {
    constructor(props) {
        super();

        var sha1 = require('sha1');
        let hash = sha1("dru7%SLa9^cli8*W|TR001|19.99|PLN|S");
        this.state = {
            hash: hash
        };
    };

    render() {
        return (
            <form action="https://secure.pep.pl/order/cart.html" method="post">
                <input type="hidden" name="amount" value="19.99"/>
                <input type="hidden" name="currency" value="PLN"/>
                <input type="hidden" name="merchant_id" value="680776a400b5579314394adcfd3d53bb"/>
                <input type="hidden" name="description" value="TR001" />
                <input type="hidden" name="transaction_description" value="Product 1 transaction" />
                <input type="hidden" name="transaction_type" value="S"/>
                <input type="hidden" name="back_url" value="http://johns-shop.com/purchased"/>
                <input type="hidden" name="language" value="pl"/>
                <input type="hidden" name="hash" value={this.state.hash} />
                <button type="submit">Zapłać z PeP</button>
            </form>
        )
    }
}

export default CoursePayComponent;