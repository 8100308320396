import React from 'react';
import Grid from '@material-ui/core/Grid';
import DiscountService from './discountService';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Nazwa' },
    { id: 'code', numeric: false, disablePadding: false, label: 'Kod' },
    { id: 'dateFrom', numeric: false, disablePadding: false, label: 'Data od' },
    { id: 'dateTo', numeric: false, disablePadding: false, label: 'Data do' },
    { id: 'isPercent', numeric: false, disablePadding: false, label: 'Kwotowo/Procentowo' },
    { id: 'isUsed', numeric: false, disablePadding: false, label: 'Użyty' },
    { id: 'discountValue', numeric: true, disablePadding: false, label: 'Wartość' }
];

class DiscountComponent extends React.Component {

    constructor(props) {
        super();

        this.state = {
            order: 'asc',
            orderBy: '-id',
            selected: [],
            page: 1,
            dense: false,
            rowsPerPage: 10,
            search: '',
            creationDateFrom: null,
            creationDateTo: null,
            size: 0,
            rows: []
        };

        this.setState({ emptyRows: this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage) })
        this.refreshItems();
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({ order: 'asc' });
        this.setState({ orderBy: property });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.rows.map((n) => n.id);
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, name) => {
        const selectedIndex = this.state.selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    refreshItems = async () => {
        let discounts = await DiscountService.query({ order: (this.state.order == 'desc' ? '-' : '') + this.state.orderBy, limit: 10, start: (this.state.page - 1) * 10, end: (this.state.page - 1) * 10 + 10, page: this.state.page, name: this.state.search, userProfileName: this.state.userProfileName, creationDateFrom: this.state.creationDateFrom, creationDateTo: this.state.creationDateTo });
        this.setState({ rows: discounts.items, size: discounts.length   });
    }

    showFilter = async () => {
        this.setState({ isFilter: !this.state.isFilter })
    };

    handleChangePage = (event, newPage) => {
        this.state.page = newPage + 1;
        this.setState({ page: newPage + 1 });
        this.refreshItems();
    };

    onSelectAllClick = () => {
        let selected = [];
        for (let i = 0; i < this.state.rows.length; i++) {
            selected.push(this.state.rows[i].id);
        }
        this.setState(selected);
    };

    createSortHandler = (columnId) => {
        if (columnId != 'action') {
            this.state.order = columnId == this.state.orderBy && this.state.order != 'desc' ? 'desc' : 'asc';
            this.state.orderBy = columnId;
            this.setState({ orderBy: columnId });
            this.refreshItems();
        }
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };

    remove = async (id) => {
        if (window.confirm('Czy na pewno chcesz usunąć kod zniżkowy?')) {
            await DiscountService.remove(id);
            this.refreshItems();
        }
    };

    removeDiscount = async () => {
        if (window.confirm('Czy na pewno chcesz usunąć kod zniżkowy?')) {
            for (let i = 0; i < this.state.selected.length; i++) {
                await DiscountService.remove(this.state.selected[i]);
            }
            this.refreshItems();
        }
    };

    clearFilter = () => {
        this.setState({
            userProfileName: '',
            creationDateFrom: null,
            creationDateTo: null
        });
        this.state.userProfileName = '';
        this.state.creationDateFrom = null;
        this.state.creationDateTo = null;
        this.refreshItems();
    }

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="wrapper">
                    <div className="border-bottom">
                        <Grid container spacing={3}>
                            <Grid item xs={3} item>
                                <h1 className="no-border">
                                    Kody zniżkowe
                                </h1>
                            </Grid>
                            <Grid item xs={6} item className="filter not-mobile users-filter">
                                <Link className="blue-font2 add-user" to="/discountAdd/0"><i className="playlist"></i>&nbsp;Dodaj nowy kod</Link>
                                <a className="gray-font2 not-mobile">Akcje grupowe: </a>
                                <a className="blue-font2 not-mobile" onClick={(event) => this.removeUsers()}><i className="remove"></i>&nbsp;Usuń</a>
                                <a className="gray-font2 not-mobile">Filtry: </a>
                                <a className="blue-font2 not-mobile"><i className="search"></i>&nbsp;<TextField label="Szukaj" className="searchInput" onChange={(text) => { this.state.search = text.target.value; this.refreshItems(); }} /></a>
                            </Grid>
                            <Grid item xs={3} item>
                                
                            </Grid>
                        </Grid>
                        {this.state.isFilter &&
                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={1} item className="filter">
                                        <a onClick={(event) => this.clearFilter()} className="blue-font"><i className="block"></i>&nbsp;Wyczyść filtr</a>
                                    </Grid>
                                </Grid>
                                <div className="space"></div>
                                <br />
                                <br />
                            </div>
                        }
                    </div>
                    <div className="space"></div>
                    <div>
                        <Paper>
                            <TableContainer>
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.rows.length}
                                                    checked={this.state.rows.length > 0 && this.state.selected.length === this.state.rows.length}
                                                    onChange={this.handleSelectAllClick}
                                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                                />
                                            </TableCell>
                                            {headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={headCell.numeric ? 'right' : 'left'}
                                                    padding={headCell.disablePadding ? 'none' : 'default'}
                                                    sortDirection={this.state.order}
                                                    onClick={(event) => this.createSortHandler(headCell.id)}
                                                >
                                                    {!headCell.disableSorting && <TableSortLabel
                                                        active={this.state.orderBy === headCell.id}
                                                        direction={this.state.order}
                                                    >
                                                        {headCell.label}
                                                    </TableSortLabel>}
                                                    {headCell.disableSorting &&
                                                        <span>{headCell.label}</span>}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.rows.map((row, index) => {
                                            const isItemSelected = this.isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}>
                                                    <TableCell padding="checkbox" onClick={(event) => this.handleClick(event, row.id)}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell align="left">{row.email}</TableCell>
                                                    <TableCell align="left">{row.code}</TableCell>
                                                    <TableCell align="left">{row.dateFrom}</TableCell>
                                                    <TableCell align="left">{row.dateTo}</TableCell>
                                                    <TableCell align="left">{row.isPercent}</TableCell>
                                                    <TableCell align="left">{row.isUsed}</TableCell>
                                                    <TableCell align="right">{row.discountValue}</TableCell>
                                                    <TableCell align="left"><i className="remove" onClick={(event) => this.remove(row.id)}></i></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {this.state.emptyRows > 0 && (
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={this.state.size}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page - 1}
                                onChangePage={this.handleChangePage}
                            />
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default DiscountComponent;