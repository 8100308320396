import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import DiscountService from './discountService';
import FileUpload from './../partials/fileUpload';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import format from "date-fns/format";
import { Checkbox } from '@material-ui/core';

class DiscountAddComponent extends React.Component {

    constructor(props) {
        super();
        this.state = {
            validationMessage: "",
            model: { id: 0, email: '', code:'', dateFrom: null, dateTo: null, isPercent: false, isAmount: false, discountValue: 0 }
        };

        this.load(props.match.params.id);
    }

    load = async (id) => {
        
        if (id > 0) {
            let discount = await DiscountService.get(id);
            this.setState({ model: discount });
        }
    }

    save = async (event) => {
        event.preventDefault();
        
        if (this.state.model.email == null || this.state.model.email == '') {
            this.setState({ validationMessage: "Pole email jest wymagane" });
            return;
        }

        if (this.state.model.code == null || this.state.model.code == '') {
            this.setState({ validationMessage: "Pole kod jest wymagane" });
            return;
        }

        this.state.model.discountValue = parseFloat(this.state.model.discountValue);

        let discount = await DiscountService.save(this.state.model);
        if (discount.error != null && discount.error != '') {
            this.setState({ validationMessage: discount.error });
            return;
        }
        else {
            window.location.href = "/discount";
        }
    };

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="wrapper">
                    {this.state.model.id <= 0 && <h1>
                        Dodaj nowy kod
                    </h1>}
                    {this.state.model.id > 0 && <h1>
                        Edycja kodu
                    </h1>}
                    <div className="clear"></div>
                    <div className="space"></div>
                    {!this.state.isMyProfile &&
                        <Link to={"/discount"} className="bread"><i className="back"></i>&nbsp;Lista kodów zniżkowych</Link>
                    }
                    <Container component="main">
                        <div className="center-form">
                            <form validate="true" autoComplete="off" onSubmit={(event) => this.save(event)}>
                                <Grid container spacing={3}>
                                    <div className="validation-error">{this.state.validationMessage}</div>
                                    <div className="clear"></div>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <TextField required label="Email" fullWidth value={this.state.model.email} onChange={(text) => { let model = { ...this.state.model }; model.email = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <TextField required label="Kod" fullWidth value={this.state.model.code} onChange={(text) => { let model = { ...this.state.model }; model.code = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <Checkbox label="isPercent" checked={this.state.model.isPercent} onChange={(text) => { let model = { ...this.state.model }; model.isPercent = text.target.checked; model.isAmount = false; this.setState({ model: model }) }} />
                                        Procentowo
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <Checkbox label="isPercent" checked={this.state.model.isAmount} onChange={(text) => { let model = { ...this.state.model }; model.isAmount = text.target.checked; model.isPercent = false; this.setState({ model: model }) }} />
                                        Kwotowo
                                    </Grid>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                            <DatePicker  label="Data od" format="dd-MM-yyyy" value={this.state.model.dateFrom} onChange={(text) => { let model = { ...this.state.model }; model.dateFrom = text; this.setState({ model: model }) }}  />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                            <DatePicker label="Data do" format="dd-MM-yyyy" value={this.state.model.dateTo} onChange={(text) => { let model = { ...this.state.model }; model.dateTo = text; this.setState({ model: model }) }} />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <TextField required label="Wartość" type="number" fullWidth value={this.state.model.discountValue} onChange={(text) => { let model = { ...this.state.model }; model.discountValue = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <Grid item xs={6} item>
                                    </Grid>
                                </Grid>                                
                                <div className="space"></div>
                                <div className="center">
                                    <Button type="submit" color="primary">
                                        Zapisz
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        )
    }
}

export default DiscountAddComponent;