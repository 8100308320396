import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { Checkbox } from '@material-ui/core';
import AuthService from './authService';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import format from "date-fns/format";
import CircularProgress from '@material-ui/core/CircularProgress';
import Preloader from './../partials/preloader';
import { FormControlLabel } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class RegisterComponent extends React.Component {

    constructor() {
        super();
        let maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        this.state = {
            validationMessage: "",
            validationMessageGreen: "",
            isSaving: false,
            model: { birthDate: null },
            maxDate: format(maxDate, 'yyyy-MM-dd'),
            minDate: format(new Date(1900,1,1), 'yyyy-MM-dd'),
        };
    }

    register = async (event) => {
        event.preventDefault();
        if (this.state.model.birthDate == null) {
            this.setState({ validationMessage: "Pole data urodzenia jest wymagane" });
            return false;
        }
        if (!this.state.model.firstCheck || !this.state.model.secondCheck || !this.state.model.thirdCheck) {
            this.setState({ validationMessage: "Zaznacz wymagane zgody" });
            return false;
        }
        this.setState({ isSaving: true });
        event.preventDefault();
        let user = await AuthService.register(this.state.model);
        if (user.error != null && user.error != '') {
            this.setState({ isSaving: false });
            this.setState({ validationMessage: user.error });
        }
        else {
            this.setState({ isSaving: false });
            this.setState({ validationMessageGreen: 'Zostałeś pomyślnie zarejestrowany, sprawdź maila aby kontynować rejestracje', validationMessage: '' });
            const timer = setTimeout(() => {
                window.location.href = "/login";
            }, 5000);
            return () => {
                clearTimeout(timer);
            }
        }
    };

    render() {
        return (
            <div>
                {this.state.isSaving && <Preloader />}
                {!this.state.isSaving && 
                    <Grid container className="login-grid">
                        <Grid item xs={9}>
                            <div className="login-background not-mobile">
                                <br/>
                                <br />
                            <br />
                            <table className="white-background">
                                <tr>
                                    <td>
                                        <Checkbox onChange={(text) => this.state.model.firstCheck = text.target.checked}/>
                                    </td>
                                    <td>
                                        Oświadczam, że zapoznałam/-em się i zrozumiałam/-em treść <a href="/images/regulamin.docx.pdf">Regulaminu</a> świadczenia usług drogą elektroniczną  oraz akceptuję wszystkie jego postanowienia. Rozumiem,że płatności za dostęp do treściaudiowizualnych na Platformie elektronicznej będą dokonywane za pośrednictwem zewnętrznego Operatora płatności.Oświadczam, że zapoznałam/-em się z warunkami świadczenia usług przez Operatora płatności i akceptuję wszystkie te warunki.(*)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Checkbox onChange={(text) => this.state.model.secondCheck = text.target.checked}/>
                                    </td>
                                    <td>
                                        Jestem świadoma/-y, że szkolenia audiowizualne oferowane na Platformie szkoleniowej mają charakter treści cyfrowych nie zapisanych na nośniku materialnym, a tym samym udostępnienie mi tych treści na Platformie szkoleniowej przed upływem ustawowego terminu do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa lub na odległość spowoduje utracenie przeze mnie prawa do odstąpienia od umowy na tej podstawie. Wyrażam zgodę na udostępnienie mi tych treści w Platformie szkoleniowej niezwłocznie po ich wykupieniu w serwisie Operatora płatności.(*)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Checkbox onChange={(text) => this.state.model.thirdCheck = text.target.checked}/>
                                    </td>
                                    <td>
                                        Oświadczam, że zapoznałam/-em się i zrozumiałam/-em treść <a href="/images/klauzula.docx.pdf">Klauzuli informacyjnej oraz Polityki cookies</a>. Wyrażam zgodę na przetwarzanie moich danych osobowych zamieszczonych przeze mnie w formularzu rejestracyjnym w celach określonych w Klauzuli informacyjnej oraz Polityce cookies. Zostałam/-em poinformowana/-y o tym, że podanie przeze mnie danych osobowych jest dobrowolne, ale niezbędne do zawarcia Umowy, a także o prawie dostępu do swoich danych, poprawiania ich oraz wycofania udzielonej zgody w dowolnym momencie oraz o pozostałych informacjach wynikających z art. 13 RODO. (*)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Checkbox onChange={(text) => this.state.model.isNewsletter = text.target.checked}/>
                                    </td>
                                    <td>
                                        Chcę otrzymywać informacje handlowe oraz newsletter i w związku z tym zgadzam się na otrzymywanie takich treści w dowolnym czasie na podany przeze mnie wyżej adres poczty elektronicznej e-mail. Zostałam/-em poinformowany o tym, że niniejsza zgoda jest dobrowolna i że mogę wycofać tak udzieloną zgodę w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem. Rozumiem, że moja zgoda jest dobrowolna, jednakże w przypadku jej nieudzielenia bądź późniejszego jej wycofania, jak również przesłania wniosku o zmianę lub usunięcie moich danych, stracę możliwość uczestniczenia w działaniach marketingowych Usługodawcy.
                                    </td>
                                </tr>
                            </table>
                            </div>
                            <div className="space"></div>
                        </Grid>
                        <Grid item xs={3}>
                            <Container component="main" maxWidth="xs">
                                <a className="logo" href="/">
                                </a>
                                <div>
                                    <form className="login-form" validate="true" autoComplete="off" onSubmit={(event) => this.register(event)}>
                                        <div className="validation-error">{this.state.validationMessage}</div>
                                        <div className="validation-green">{this.state.validationMessageGreen}</div>
                                        <h1>
                                            Zarejestruj się
                                        </h1>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} item>
                                                <TextField required label="Adres e-mail" type="email" fullWidth autoFocus onChange={(text) => this.state.model.email = text.target.value}/>
                                            </Grid>
                                            <div className="space"></div>
                                            <Grid item xs={6} item>
                                                <TextField required label="Imię" fullWidth onChange={(text) => this.state.model.firstName = text.target.value} />
                                            </Grid>
                                            <Grid item xs={6} item>
                                                <TextField required label="Nazwisko" fullWidth onChange={(text) => this.state.model.lastName = text.target.value} />
                                            </Grid>
                                            <div className="space"></div>
                                            <Grid item xs={6} item>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel htmlFor="outlined-age-native-simple">Płeć</InputLabel>
                                                    <Select native label="Płęć" onChange={(text) => this.state.model.sex = parseInt(text.target.value)} inputProps={{ name: 'sex', id: 'outlined-sex-native-simple' }} >
                                                        <option value={1}>Mężczyzna</option>
                                                        <option value={2}>Kobieta</option>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} item>
                                                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                                    <DatePicker label="Data urodzenia" format="dd-MM-yyyy" required minDate={this.state.minDate} maxDate={this.state.maxDate} value={this.state.model.birthDate} onChange={(text) => { let model = { ...this.state.model }; model.birthDate = text; this.setState({ model: model }) }} />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={6} item>
                                                <TextField label="Kod pocztowy" inputProps={{ maxLength: 6 }} fullWidth onChange={(text) => this.state.model.postalCode = text.target.value} maxLength={5} />
                                            </Grid>
                                            <div className="space"></div>
                                        <Grid item xs={6} item>
                                            <PhoneInput
                                                country={'pl'}
                                                value={this.state.model.phoneNumber}
                                                onChange={phone => this.state.model.phoneNumber = phone}
                                            />
                                            </Grid>
                                            <Grid item xs={12} item>
                                                <TextField label="Numer prawa wykonywania zawodu" fullWidth onChange={(text) => this.state.model.position = text.target.value} />
                                            </Grid>
                                        </Grid>
                                        <div className="space"></div>
                                        <table className="white-background only-mobile">
                                            <tr>
                                                <td>
                                                    <Checkbox onChange={(text) => this.state.model.firstCheck = text.target.checked} />
                                                </td>
                                                <td>
                                                    Oświadczam, że zapoznałam/-em się i zrozumiałam/-em treść <a href="/images/regulamin.docx">Regulaminu</a> świadczenia usług drogą elektroniczną  oraz akceptuję wszystkie jego postanowienia. Rozumiem,że płatności za dostęp do treściaudiowizualnych na Platformie elektronicznej będą dokonywane za pośrednictwem zewnętrznego Operatora płatności.Oświadczam, że zapoznałam/-em się z warunkami świadczenia usług przez Operatora płatności i akceptuję wszystkie te warunki.(*)
                                                </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Checkbox onChange={(text) => this.state.model.secondCheck = text.target.checked} />
                                                        </td>
                                                        <td>
                                                            Jestem świadoma/-y, że szkolenia audiowizualne oferowane na Platformie szkoleniowej mają charakter treści cyfrowych nie zapisanych na nośniku materialnym, a tym samym udostępnienie mi tych treści na Platformie szkoleniowej przed upływem ustawowego terminu do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa lub na odległość spowoduje utracenie przeze mnie prawa do odstąpienia od umowy na tej podstawie. Wyrażam zgodę na udostępnienie mi tych treści w Platformie szkoleniowej niezwłocznie po ich wykupieniu w serwisie Operatora płatności.(*)
                                                </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Checkbox onChange={(text) => this.state.model.thirdCheck = text.target.checked} />
                                                        </td>
                                                        <td>
                                                            Oświadczam, że zapoznałam/-em się i zrozumiałam/-em treść <a href="/images/klauzula.docx">Klauzuli informacyjnej oraz Polityki cookies</a>. Wyrażam zgodę na przetwarzanie moich danych osobowych zamieszczonych przeze mnie w formularzu rejestracyjnym w celach określonych w Klauzuli informacyjnej oraz Polityce cookies. Zostałam/-em poinformowana/-y o tym, że podanie przeze mnie danych osobowych jest dobrowolne, ale niezbędne do zawarcia Umowy, a także o prawie dostępu do swoich danych, poprawiania ich oraz wycofania udzielonej zgody w dowolnym momencie oraz o pozostałych informacjach wynikających z art. 13 RODO. (*)
                                                </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Checkbox onChange={(text) => {this.state.model.isNewsletter = text.target.checked}} />
                                                        </td>
                                                        <td>
                                                            Chcę otrzymywać informacje handlowe oraz newsletter i w związku z tym zgadzam się na otrzymywanie takich treści w dowolnym czasie na podany przeze mnie wyżej adres poczty elektronicznej e-mail. Zostałam/-em poinformowany o tym, że niniejsza zgoda jest dobrowolna i że mogę wycofać tak udzieloną zgodę w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem. Rozumiem, że moja zgoda jest dobrowolna, jednakże w przypadku jej nieudzielenia bądź późniejszego jej wycofania, jak również przesłania wniosku o zmianę lub usunięcie moich danych, stracę możliwość uczestniczenia w działaniach marketingowych Usługodawcy.
                                                </td>
                                            </tr>
                                        </table>
                                        <Button type="submit" fullWidth color="primary">
                                             Zarejestruj
                                        </Button>

                                    </form>
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

export default RegisterComponent;