import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import FileUpload from './../partials/fileUpload';
import CourseService from './courseService';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Preloader from './../partials/preloader';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class CourseAddComponent extends React.Component {
    constructor(props) {
        super();
        this.state = {
            isFirst: false,
            isAddQuestion: false,
            isEditQuestion: false,
            model: { id: 0, name: '', startDate: null, endDate: null, quantity: '', percentPass: '', goingAddress: '', goingName: '', price: '', description: '', place: '', mediaSrc: '', certSrc: '', priceNet:'', vat:'', items: [{ name: "Sekcja 1", items: [], files: [] }], users:[] },
            newQuestion: { items: [] },
            newFile: {},
            search:'',
            selectedSection: -1,
            isAddFile: false,
            isEditFile: false,
            users: [],
            isCourseAsPrevious: props.match.params.id < 0,
            isSaving: false,
        };

        if (props.match.params.id < 0)
            props.match.params.id = -1 * props.match.params.id;

        if (props.match.params.id > 0 && props.match.params.id != '0') {
            this.setState({ isSaving: true });    
            this.load(props.match.params.id);
        }

        this.setState({ isSaving: false });
    };

    onEditorStateChange = (editorState) => {
        let model = { ...this.state.model };
        model.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({
            editorState,
            model,
        });
    };

    load = async (id) => {
        if (id > 0) {
            let item = await CourseService.get(id, false);
            if (this.state.isCourseAsPrevious) {
                item.id = 0;
                item.users = [];
            }
            this.setState({ model: item, users: item.users, isSaving: false });
            const html = item.description;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({
                    editorState: editorState,
                });
            }
        }
        else
            this.setState({ isSaving: false });
    }

    showSections = () => {
        this.setState({ isFirst: true });
    };

    back= () => {
        this.setState({ isFirst: false });
    };

    addSection = () => {
        let model = { ...this.state.model };
        model.items.push({ name: "Sekcja " + (model.items.length + 1), items:[], files:[] })
        this.setState({ model: model });
    };

    addQuestion = (section) => {
        let model = { ...this.state.model };
        this.setState({ isAddQuestion: true, newQuestion: { items: [] }, selectedSection: model.items.indexOf(section)});
    };

    addFile = (section) => {
        let model = { ...this.state.model };
        this.setState({ isAddFile: true, newFile: { }, selectedSection: model.items.indexOf(section) });
    };

    editQuestion = (question, section) => {
        let model = { ...this.state.model };
        let index = section.items.indexOf(question);
        this.setState({ isAddQuestion: false, isEditQuestion: true, newQuestion: question, selectedSection: model.items.indexOf(section), indexEditQuestion: index, questionValidationMessage: '' });
    };

    editFile = (file, section) => {
        let model = { ...this.state.model };
        let index = section.files.indexOf(file);
        this.setState({ isAddQuestion: false, isEditFile: true, newFile: file, selectedSection: model.items.indexOf(section), indexEditFile: index });
    };

    removeQuestion = (question, section) => {
        let index = section.items.indexOf(question);
        if (window.confirm('Czy na pewno chcesz usunąć pytanie?')) {
            let model = { ...this.state.model };
            let selSection = model.items.indexOf(section);
            model.items[selSection].items.splice(index, 1);
            this.setState({ model: model });
        }
    };

    removeFile = (file, section) => {
        let index = section.files.indexOf(file);
        if (window.confirm('Czy na pewno chcesz usunąć plik?')) {
            let model = { ...this.state.model };
            let selSection = model.items.indexOf(section);
            model.items[selSection].files.splice(index, 1);
            this.setState({ model: model });
        }
    };

    removeSection = (section) => {
        if (window.confirm('Czy na pewno chcesz usunąć sekcje?')) {
            let model = { ...this.state.model };
            let selSection = model.items.indexOf(section);
            model.items.splice(selSection,1);
            this.setState({ model: model });
        }
    };

    addAnswer = () => {
        let newQuestion = { ...this.state.newQuestion };
        newQuestion.items.push({});
        this.setState({ newQuestion: newQuestion, questionValidationMessage: '' });
    };

    handleClose = () => {
        this.setState({ isAddQuestion: false, isEditQuestion: false, isAddFile: false, isEditFile: false });
    };

    setAnswer = (answer,text) => {
        let newQuestion = { ...this.state.newQuestion };
        newQuestion.items[newQuestion.items.indexOf(answer)].name = text.target.value;
        this.setState({ newQuestion: newQuestion });
    };

    setQuestionName = (text) => {
        let newQuestion = { ...this.state.newQuestion };
        newQuestion.name = text.target.value;
        this.setState({ newQuestion: newQuestion });
    };

    setFileName = (text) => {
        let newFile = { ...this.state.newFile };
        newFile.name = text.target.value;
        this.setState({ newFile: newFile });
    }

    setFileDescription = (text) => {
        let newFile = { ...this.state.newFile };
        newFile.description = text.target.value;
        this.setState({ newFile: newFile });
    }

    setFileYoutube = (text) => {
        let newFile = { ...this.state.newFile };
        newFile.youtube = text.target.value;
        this.setState({ newFile: newFile });
    }

    setFileSrc = (text,file) => {
        let newFile = { ...this.state.newFile };
        newFile.src = text;
        newFile.fileSrc = file;
        this.setState({ newFile: newFile });
    }

    setFileCertSrc = (text,file) => {
        let model = { ...this.state.model };
        model.certFile = file;
        model.certSrc = text;
        this.setState({ model: model });
    }

    getFileCertSrc = () => {
        let model = { ...this.state.model };
        model.editCertFile = null;
        return model.certSrc;
    }

    getFileMediaSrc = () => {
        let model = { ...this.state.model };
        return model.mediaSrc;
    }

    setFileMediaSrc = (text, file) => {
        let model = { ...this.state.model };
        debugger;
        model.mediaFile = file;
        model.editMediaFile = null;
        model.mediaSrc = text;
        this.setState({ model: model });
    }

    setRadioAnswer = (answer, isTrue) => {
        let newQuestion = { ...this.state.newQuestion };
        newQuestion.items[newQuestion.items.indexOf(answer)].isCorrect = isTrue;
        this.setState({ newQuestion: newQuestion });
    };

    saveAnswer = () => {
        let newQuestion = { ...this.state.newQuestion };
        let isCorrect = false;
        for (let i = 0; i < this.state.newQuestion.items.length; i++) {
            if (this.state.newQuestion.items[i].isCorrect) {
                isCorrect = true;
            }
        }

        if (!isCorrect) {
            this.setState({
                questionValidationMessage: "Zaznacz poprawną odpowiedź",
            });
            return;
        }

        let model = { ...this.state.model };
        let section = model.items[this.state.selectedSection];
        if (this.state.isEditQuestion)
            section.items[this.state.indexEditQuestion] = newQuestion;
        else
            section.items.push(newQuestion);

        this.setState({ model: model, isAddQuestion: false, isEditQuestion: false });
    };

    saveFile = () => {
        let newFile = { ...this.state.newFile };
        let model = { ...this.state.model };
        let section = model.items[this.state.selectedSection];
        if (this.state.isEditFile)
            section.files[this.state.indexEditFile] = newFile;
        else
            section.files.push(newFile);

        this.setState({ isAddFile: false, isEditFile: false, model: model });
    };

    courseAdd = () => {
        window.location.href = "/courseAdd/-"+this.state.model.id;
    };

    save = async (event) => {
        event.preventDefault();
        if (this.state.model.id<=0 && (this.state.model.mediaSrc === null || this.state.model.mediaSrc === "")) {
            this.setState({ validationMessage: 'Pole grafika jest wymagane' });
            return;
        }
        
        if (this.state.model.id <= 0 && (this.state.model.certSrc === null || this.state.model.certSrc === "")) {
            this.setState({ validationMessage: 'Pole certyfikat jest wymagane' });
            return;
        }

        if (this.state.model.percentPass > 100) {
            this.setState({ validationMessage: 'Pole wymagany % nie może być większy niż 100' });
            return;
        }
        this.state.model.users = [];
        this.state.model.editMediaFile = null;
        this.state.model.editCertFile = null;

        let course = await CourseService.save(this.state.model);
        if (course.error != null && course.error != '') {
            this.setState({ validationMessage: course.error });
        }
        else {
            window.location.href = "/courses/0/0";
        }
    };

    searchUsers = (sort) => {
        let users = this.state.model.users.filter(user => user.fullName.indexOf(this.state.search) >= 0 || this.state.search==null);
        
        if (sort == 2) {
            users = users.sort((a, b) => a.lastDate.localeCompare(b.lastDate));
        }

        if (sort == 1) {
            users = users.sort((a, b) => b.lastDate.localeCompare(a.lastDate));
        }

        if (sort == 4) {
            users = users.sort((a, b) => a.correctAnswer - b.correctAnswer);
        }

        if (sort == 3) {
            users = users.sort((a, b) => b.correctAnswer - a.correctAnswer);
        }

        if (sort == 6) {
            users = users.sort((a, b) => a.fullName.localeCompare(b.fullName));
        }

        if (sort == 5) {
            users = users.sort((a, b) => b.fullName.localeCompare(a.fullName));
        }

        this.setState({ users: users });
    }

    onContentStateChange = contentState => {
        console.log('as HTML:', draftToHtml(contentState));
        this.setState({ contentState });
    }

    render() {
        return (
            <div>
            { this.state.isSaving && <Preloader />}
                {!this.state.isSaving &&
                    <Grid container>
                        <Grid item xs={12} className="wrapper">

                            {this.state.model.id <= 0 && <h1>
                                Dodaj nowy kurs
                        </h1>}
                            {this.state.model.id > 0 && <h1>
                                Edycja kursu
                            <a onClick={() => this.courseAdd()} className="blue-font2 add-course"><i className="playlist"></i>&nbsp;Dodaj nowy kurs na podstawie tego</a>
                            </h1>}

                            <Container component="main" className="wrapper-main">
                                {!this.state.isFirst ?
                                    <Grid container>
                                        <Grid item xs={6} item className="border-right">
                                            <Link to={"/courses/0/0"} className="bread"><i className="back"></i>&nbsp;Lista kursów</Link>
                                            <div className="clear"></div>
                                            <form validate="true" autoComplete="off" onSubmit={(event) => this.save(event)}>
                                                <Grid container spacing={3}>
                                                    <div className="validation-error ml-5">{this.state.validationMessage}</div>
                                                    <div className="clear"></div>
                                                    <div className="space"></div>
                                                    <Grid item xs={6} item>
                                                        <TextField required label="Nazwa kursu" fullWidth autoFocus value={this.state.model.name} inputProps={{ maxLength: 70 }} onChange={(text) => { let model = { ...this.state.model }; model.name = text.target.value; this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <Grid item xs={6} item>
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <Grid item xs={6} item>
                                                        <a className="blue-font left">Dodaj grafikę</a>
                                                        <FileUpload type="media" modelFile={this}></FileUpload>
                                                        {this.state.model.editMediaFile != null && <img className="avatar-form" src={this.state.model.editMediaFile} />

                                                        }
                                                    </Grid>
                                                    <Grid item xs={6} item>
                                                        <a className="blue-font left">Dodaj certyfikat</a>
                                                        <FileUpload type="cert" modelFile={this}></FileUpload>
                                                        {this.state.model.editCertFile != null && <img className="avatar-form" src={this.state.model.editCertFile} />

                                                        }
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <Grid item xs={6} item>
                                                        <TextField required label="Cena [Netto]" fullWidth value={this.state.model.priceNet} type="number" onChange={(text) => { let model = { ...this.state.model }; model.priceNet = parseFloat(text.target.value); this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <Grid item xs={6} item>
                                                    <TextField required label="VAT" fullWidth value={this.state.model.vat} type="number" onChange={(text) => { let model = { ...this.state.model }; model.vat = parseFloat(text.target.value); this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <Grid item xs={6} item>
                                                        <TextField required label="Cena [PLN]" fullWidth value={this.state.model.price} type="number" onChange={(text) => { let model = { ...this.state.model }; model.price = parseFloat(text.target.value); this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <Grid item xs={6} item>
                                                        <TextField required label="Miejsce" fullWidth value={this.state.model.place} onChange={(text) => { let model = { ...this.state.model }; model.place = text.target.value; this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <Grid item xs={6} item>
                                                        <TextField required label="% potrzebny do zdania" fullWidth type="number" value={this.state.model.percentPass} onChange={(text) => { let model = { ...this.state.model }; model.percentPass = parseInt(text.target.value); this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <Grid item xs={6} item>
                                                        <TextField required label="Max ilość prób" fullWidth type="number" value={this.state.model.quantity} onChange={(text) => { let model = { ...this.state.model }; model.quantity = parseInt(text.target.value); this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <Grid item xs={12} item>
                                                        <TextField label="Going url" fullWidth value={this.state.model.goingAddress} onChange={(text) => { let model = { ...this.state.model }; model.goingAddress = text.target.value; this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <Grid item xs={12} item >
                                                        <TextField label="Going nazwa" fullWidth value={this.state.model.goingName} onChange={(text) => { let model = { ...this.state.model }; model.goingName = text.target.value; this.setState({ model: model }) }} />
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <Grid item xs={12} item className="multiline">
                                                    <Editor
                                                        editorState={this.state.editorState}
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />
                                                    </Grid>
                                                </Grid>
                                                <div className="space"></div>
                                                <Grid container spacing={3}>
                                                    <div className="space"></div>
                                                    <Grid item xs={6} item>
                                                        <Button type="submit" color="primary" fullWidth>
                                                            Zapisz
                                                </Button>
                                                    </Grid>
                                                    <Grid item xs={6} item>
                                                        {this.state.model.id <= 0 && <Button color="primary" fullWidth onClick={() => this.showSections()}>
                                                            Dodaj zawartość kursu
                                                </Button>}
                                                        {this.state.model.id > 0 && <Button color="primary" fullWidth onClick={() => this.showSections()}>
                                                            Edytuj zawartość kursu
                                                </Button>}
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Grid>
                                        <Grid item xs={6} item>
                                            <div className="right-box">
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <p className="black-font">Lista uczestników</p>
                                                        </td>
                                                        <td>
                                                            <div className="filter-no-background">
                                                                <a className="blue-font2"><i className="search"></i>&nbsp;<TextField label="Szukaj" className="searchInput" onChange={(text) => { this.state.search = text.target.value; this.searchUsers(); }} /></a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="sort-font">Data:</span>&nbsp;&nbsp;
                                                        {this.state.order === 1 ? <a onClick={() => { this.setState({ order: 2 }); this.searchUsers(2) }} className="gold-font">Najstarsza <i className="swap_vert"></i></a> : <a onClick={() => { this.setState({ order: 1 }); this.searchUsers(1) }} className="gold-font">Najnowsza <i className="swap_vert"></i></a>}
                                                        </td>
                                                        <td>
                                                            <span className="sort-font">Wynik:</span>&nbsp;&nbsp;
                                                    {this.state.order2 === 1 ? <a onClick={() => { this.setState({ order2: 2 }); this.searchUsers(4) }} className="gold-font">Najwyższy <i className="swap_vert"></i></a> : <a onClick={() => { this.setState({ order2: 1 }); this.searchUsers(3) }} className="gold-font">Najmniejszy <i className="swap_vert"></i></a>}
                                                        </td>
                                                        <td>
                                                            {this.state.order3 === 1 ? <a onClick={() => { this.setState({ order3: 2 }); this.searchUsers(6) }} className="gold-font">Z do A <i className="swap_vert"></i></a> : <a onClick={() => { this.setState({ order3: 1 }); this.searchUsers(5) }} className="gold-font">A do Z <i className="swap_vert"></i></a>}
                                                        </td>
                                                    </tr>
                                                </table>
                                                <Grid container>
                                                    {this.state.users.map((user, userId) => {
                                                        return (
                                                            <Grid item spacing={3} xs={6}>
                                                                <div>
                                                                    <Link className="description-avatar" to={"/courseResultShow/" + this.state.model.id + "/" + user.userId}>
                                                                        <Grid container>
                                                                            <Grid item spacing={3} xs={1}>
                                                                                {user.isPass && <a className="done"></a>}
                                                                            </Grid>
                                                                            <Grid item spacing={3} xs={4}>
                                                                                <img src={user.avatar} />
                                                                            </Grid>
                                                                            <Grid item spacing={3} xs={7}>
                                                                                <p className="black-font">{user.fullName} - <span className="gold-font">{user.correctAnswer} %</span></p>
                                                                                <p className="gray-small">{user.email}</p>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Link>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid> :
                                    <Grid container>
                                        {this.state.model.id > 0 && <a className="bread" onClick={() => this.back()}><i className="back"></i>&nbsp;Edycja kursu</a>}
                                        {this.state.model.id <= 0 && <a className="bread" onClick={() => this.back()}><i className="back"></i>&nbsp;Dodanie nowego kursu</a>}
                                        {this.state.model.items.map((prop, key) => {
                                            return (
                                                <div className="fullWidth">
                                                    <h2>Sekcja {(this.state.model.items.indexOf(prop) + 1)}</h2>
                                                    <Grid container>
                                                        <Grid item xs={6} item className="border-right">
                                                            {prop.items.map((question, key) => {
                                                                return (
                                                                    <div className="left-align">
                                                                        <p>Pytanie: <strong>{question.name}</strong></p>
                                                                        <div><p>Odpowiedzi: </p>
                                                                            {question.items.map((answer, key) => {
                                                                                return (
                                                                                    <p>
                                                                                        <strong>{answer.name}</strong>
                                                                                    </p>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <div className="space"></div>
                                                                        <div>
                                                                            <a className="blue-font float-left" onClick={() => this.editQuestion(question, prop)}><i className="create"></i>&nbsp;Edytuj</a>&nbsp;&nbsp;&nbsp;
                                                                    <a className="blue-font float-left" onClick={() => this.removeQuestion(question, prop)}><i className="remove"></i>&nbsp;Usuń</a>
                                                                            <div className="clear"></div>
                                                                            <div className="space"></div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            })}
                                                            <a className="blue-font no-block" onClick={() => this.addQuestion(prop)}><i className="library"></i>&nbsp;Dodaj pytanie</a>
                                                            <div className="space"></div>
                                                        </Grid>
                                                        <Grid item xs={6} item>
                                                            <div className="right-box">
                                                                {prop.files.map((file, key) => {
                                                                    return (
                                                                        <Grid container>
                                                                            <Grid item xs={9} item>
                                                                                {file.src != null && file.src.indexOf("image/png") >= 0 &&
                                                                                    <img src={file.src} className="max-imagewidth" />
                                                                                }
                                                                                {file.src != null && file.src != '' && file.src.indexOf("image/png") <= 0 &&
                                                                                    <video src={file.src} className="max-imagewidth" controls />
                                                                                }
                                                                                {file.youtube != null && file.youtube != '' && <ReactPlayer className="max-imagewidth" url={file.youtube} />
                                                                                }
                                                                            </Grid>
                                                                            <Grid item xs={3} item>
                                                                                <p>Nazwa:&nbsp;<strong>{file.name}</strong></p>
                                                                                <div className="space"></div>
                                                                                <a className="blue-font float-left" onClick={() => this.editFile(file, prop)}><i className="create"></i>&nbsp;Edytuj</a>&nbsp;&nbsp;&nbsp;
                                                                        <a className="blue-font float-left" onClick={() => this.removeFile(file, prop)}><i className="remove"></i>&nbsp;Usuń</a>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )
                                                                })}
                                                                {prop.files.length <= 0 &&
                                                                    <a className="blue-font no-block" onClick={() => this.addFile(prop)}><i className="library"></i>&nbsp;Dodaj materiał multimedialny</a>
                                                                }
                                                                <div className="space"></div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <div className="space"></div>
                                                    <div className="center width400">
                                                        <a className="blue-font no-block center width200" onClick={() => this.addSection()}><i className="library"></i>&nbsp;Dodaj kolejną sekcje</a>
                                                        <a className="blue-font no-block center width200" onClick={() => this.removeSection(prop)}><i className="remove"></i>&nbsp;Usuń sekcje</a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Grid>
                                }
                            </Container>
                        </Grid>
                        <Dialog open={this.state.isAddQuestion || this.state.isEditQuestion} onClose={this.handleClose} aria-labelledby="form-dialog-title" className="paper-dialog" maxWidth='xl'>
                            <DialogTitle id="form-dialog-title">Dodaj pytanie</DialogTitle>
                            <DialogContent>
                                <form validate="true" autoComplete="off" >
                                    <Grid container spacing={3}>
                                        <div className="validation-error">{this.state.questionValidationMessage}</div>
                                        <div className="clear"></div>
                                        <div className="space"></div>
                                        <Grid item xs={12} item>
                                            <TextField
                                                autoFocus
                                                label="Pytanie"
                                                fullWidth
                                                value={this.state.newQuestion.name} onChange={(text) => this.setQuestionName(text)}
                                            />
                                        </Grid>
                                        <div className="space"></div>
                                        <Grid item xs={12} item>
                                            {this.state.newQuestion.items.map((answer, key) => {
                                                return (
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6} item>
                                                            <TextField
                                                                autoFocus
                                                                label="Odpowiedź"
                                                                fullWidth
                                                                value={answer.name} onChange={(text) => this.setAnswer(answer, text)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} item>
                                                            <Radio
                                                                checked={answer.isCorrect == 1}
                                                                onChange={(text) => this.setRadioAnswer(answer, 1)}
                                                                value="1" label />&nbsp;Poprawna
                                                    <Radio
                                                                checked={answer.isCorrect === 0}
                                                                onChange={(text) => this.setRadioAnswer(answer, 0)}
                                                                value="0"
                                                            />&nbsp;Niepoprawna
                                                </Grid>
                                                        <div className="space"></div>
                                                    </Grid>
                                                )
                                            })}
                                            <div className="space"></div>
                                            <a className="blue-font no-block" onClick={() => this.addAnswer()}><i className="library"></i>&nbsp;Dodaj odpowiedź</a>
                                        </Grid>
                                    </Grid>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Anuluj
                        </Button>
                                <Button onClick={this.saveAnswer} color="primary">
                                    Zapisz
                        </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.isAddFile || this.state.isEditFile} onClose={this.handleClose} aria-labelledby="form-dialog-title" className="paper-dialog" maxWidth='xl'>
                            <DialogTitle id="form-dialog-title">Dodaj materiał multimedialny</DialogTitle>
                            <DialogContent>
                                <form validate="true" autoComplete="off" >
                                    <Grid container spacing={3}>
                                        <div className="space"></div>
                                        <Grid item xs={12} item>
                                            <TextField
                                                autoFocus
                                                label="Treść"
                                                fullWidth
                                                value={this.state.newFile.name} onChange={(text) => this.setFileName(text)}
                                            />
                                        </Grid>
                                        <div className="space"></div>
                                        <Grid item xs={12} item>
                                            <TextField
                                                label="Adres youtube"
                                                fullWidth
                                                value={this.state.newFile.youtube} onChange={(text) => this.setFileYoutube(text)}
                                            />
                                        </Grid>
                                        <div className="space"></div>
                                        <Grid item xs={12} item className="multiline">
                                            <TextField label="Opis" fullWidth multiline value={this.state.newFile.description} onChange={(text) => this.setFileDescription(text)} />
                                        </Grid>
                                        <div className="space"></div>
                                        <Grid item xs={12} item>
                                            <FileUpload type="file" newFile={this}></FileUpload>
                                            {this.state.model.mediaSrc != null && this.state.model.mediaSrc != '' && this.state.model.mediaSrc.indexOf("image/png") <= 0 &&
                                                <video src={this.state.model.mediaSrc} className="max-imagewidth" controls />
                                            }
                                            {this.state.newFile.youtube != null && this.state.newFile.youtube != '' && <ReactPlayer url={this.state.newFile.youtube} />
                                            }
                                            {/*<input accept="image/*"
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button variant="raised" component="span">
                                            Dodaj plik
                                        </Button>
                                    </label>*/}
                                        </Grid>
                                    </Grid>
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Anuluj
                        </Button>
                                <Button onClick={this.saveFile} color="primary">
                                    Zapisz
                        </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                }
            </div>
        )
    }
}

export default CourseAddComponent;