
import React, {useEffect } from 'react'
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route, Redirect, withRouter, Link } from 'react-router-dom';
import LoginComponent from './components/login/loginComponent';
import LogoffComponent from './components/login/logoffComponent';
import ForgotComponent from './components/login/forgotComponent';
import ActivateComponent from './components/login/activateComponent';
import ResetComponent from './components/login/resetComponent';
import RegisterComponent from './components/login/registerComponent';
import GuardedRoute from './guard/routeGuard';
import { isAuthenticated, getLogesAs, getBearer, getTestAuth } from './components/login/authService';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { testAuthorizeAttribute, logotUser } from './components/login/authService';
import CssBaseline from '@material-ui/core/CssBaseline';
import InfoComponent from './components/home/infoComponent';
import UsersComponent from './components/users/usersComponent';
import UserAddComponent from './components/users/userAddComponent';
import DiscountAddComponent from './components/discount/discountAddComponent';
import CoursesComponent from './components/courses/coursesComponent';
import CoursesForUserComponent from './components/courses/coursesForUserComponent';
import CertsComponent from './components/certs/certsComponent';
import DiscountComponent from './components/discount/discountComponent';
import CertsForUserComponent from './components/certs/certsForUserComponent';
import CourseAddComponent from './components/courses/courseAddComponent';
import CourseShowComponent from './components/courses/courseShowComponent';
import CoursePayComponent from './components/courses/coursePayComponent';
import CourseResultShowComponent from './components/courses/courseResultShowComponent';
import CoursePreShowComponent from './components/courses/coursePreShowComponent';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

function App(props) {
  const classes = useStyles();

  const logout = () => {
    const { logoutAuth} = props;
    logoutAuth();
  }

  const NotFoundRedirect = () => <Redirect to='/' />

  return (
      <div className={classes.root}>
      <CssBaseline />
      <BrowserRouter basename={baseUrl}>
      <main className={classes.content + ' ' + (props.isAuthenticated ? (props.workerFilterOpen ? classes.workerFilterOpenMargin : '') : classes.removeMarginLeft)}>
            {props.isAuthenticated && 
                <AppBar position="static"> 
                      <Toolbar className="top">
                        <a className="logo-small" href="/">
                        </a>
                        <ul className="menu">
                              {props.role == 0 &&
                                  <li><Link className="gray-font" to={"/users"}>Użytkownicy</Link></li>
                              }
                              {props.role == 0 &&
                                  <li><Link className="gray-font" to={"/courses/0/0"}>Kursy</Link></li>
                              }
                              {props.role == 0 &&
                                  <li><Link className="gray-font" to={"/certs"}>Certyfikaty</Link></li>
                              }
                              {props.role == 0 &&
                                  <li><Link className="gray-font" to={"/discount"}>Kody zniżkowe</Link></li>
                              }
                              {props.role == 1 &&
                                  <li><Link className="gray-font" to={"/coursesForUser"}>Kursy</Link></li>
                              }
                              {props.role == 1 &&
                                  <li><Link className="gray-font" to={"/certsForUser"}>Certyfikaty</Link></li>
                              }
                              {props.role == 1 &&
                                  <li><Link className="gray-font" to={"/userAdd/0"}>Mój profil</Link></li>
                              }
                        </ul>
                        <div className="avatar">
                              {props.avatar != null && props.avatar != '' && props.avatar != 'null' && <Link to={"/userAdd/0"}><img src={props.avatar} /></Link>}
                              {(props.avatar == null || props.avatar == '' || props.avatar=="null") && <a className="av"></a>}
                              {props.role == 1 && <Link className="description-avatar" to={"/userAdd/0"}>
                                  <p>{props.fullName}</p>
                                  <p className="gray-small">{props.email}</p>
                                 </Link>
                              }
                              {props.role != 1 && <div className="description-avatar">
                                  <p>{props.fullName}</p>
                                  <p className="gray-small">{props.email}</p>
                              </div>
                              }
                        </div>
                        <div className="icons">
                            <Link to="/info"><i className="i"></i></Link>
                            <Link to="/logoff"><i className="logout"></i></Link>
                        </div> 
                    </Toolbar>
                </AppBar>
            }
          <Switch>
            <Route path="/login" exact >
                <LoginComponent></LoginComponent>
            </Route>
            <Route path="/register" exact >
                <RegisterComponent></RegisterComponent>
            </Route>
            <Route path="/activate/:id" exact component={ActivateComponent} />
            <Route path="/reset/:id" exact component={ResetComponent} />
            <Route path="/forgot" exact>
                <ForgotComponent></ForgotComponent>
            </Route>
            <Route path="/info" exact>
                <InfoComponent></InfoComponent>
            </Route>
            <GuardedRoute path='/logoff' component={LogoffComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/courses/:id/:userProfileId' component={CoursesComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/coursesForUser' component={CoursesForUserComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/courseAdd/:id' component={CourseAddComponent} exact auth={props.isAuthenticated}/>
            <GuardedRoute path='/courseShow/:id' component={CourseShowComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/courseResultShow/:id/:userId' component={CourseResultShowComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/coursePreShow/:id' component={CoursePreShowComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/coursePay' component={CoursePayComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/certs' component={CertsComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/discount' component={DiscountComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/certsForUser' component={CertsForUserComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/userAdd/:id' component={UserAddComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/discountAdd/:id' component={DiscountAddComponent} exact auth={props.isAuthenticated} />
            <GuardedRoute path='/' component={UsersComponent} auth={props.isAuthenticated} />
            <Route component={NotFoundRedirect} />
          </Switch>
      </main>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = state => ({
    isAuthenticated: localStorage.getItem('token') != null && localStorage.getItem('token') != '',
    role: localStorage.getItem('role'),
    email: localStorage.getItem('email'),
    fullName: localStorage.getItem('fullName'),
    avatar: localStorage.getItem('avatar') != null ? localStorage.getItem('avatar'):null,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);