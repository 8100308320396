import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import AuthService from './authService';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

class ForgotComponent extends React.Component {

    constructor() {
        super();
        this.state = {
            validationMessage: "",
            validationMessageGreen: "",
            model: { },
        };
    }

    register = async (event) => {
        event.preventDefault();
        let user = await AuthService.forgot(this.state.model);
        if (user.error != null && user.error != '') {
            this.setState({ validationMessage: user.error });
        }
        else {
            this.setState({ validationMessageGreen: 'Sprawdź maila aby kontynować przypomnienie hasła', validationMessage:'' });
        }
    };

    render() {
        return (
            <Grid container className="login-grid">
                <Grid item xs={9}>
                    <div className="login-background not-mobile">
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Container component="main" maxWidth="xs">
                        <a className="logo" href="/">
                        </a>
                        <div>
                            <form className="login-form" validate="true" autoComplete="off" onSubmit={(event) => this.register(event)} >
                                <div className="validation-error">{this.state.validationMessage}</div>
                                <div className="validation-green">{this.state.validationMessageGreen}</div>
                                <h1>
                                    Przypomnienie hasła
                                </h1>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} item>
                                        <TextField required label="Adres e-mail" fullWidth autoFocus onChange={(text) => this.state.model.email = text.target.value}/>
                                    </Grid>
                                </Grid>
                                <div className="space"></div>
                                <Button type="submit" fullWidth color="primary">
                                    Przypomnij
                            </Button>
                            </form>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        )
    }
}

export default ForgotComponent;
