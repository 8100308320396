import React from 'react';
import axios from 'axios';

const config = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
}

class AuthService extends React.Component {
    register = async (model) => {
        try {
            const { data } = await axios.post('api/account/register', model);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    forgot = async (model) => {
        try {
            const { data } = await axios.post('api/account/forgot', model);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    activate = async (model) => {
        try {
            const { data } = await axios.post('api/account/activate', model);
            localStorage.setItem('role', data.role);
            localStorage.setItem('fullName', data.fullName);
            localStorage.setItem('email', data.email);
            localStorage.setItem('avatar', data.avatar != null ? data.avatar : '');
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    login = async (model) => {
        try {
            const { data } = await axios.post('api/account/login', model);
            localStorage.setItem('role', data.role);
            localStorage.setItem('email', data.email);
            localStorage.setItem('avatar', data.avatar != null ? data.avatar:'');
            localStorage.setItem('fullName', data.fullName);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    get = async () => {
        try {
            const { data } = await axios.get('api/account/get',config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }
}

export default new AuthService();