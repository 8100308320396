import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline'
import CustomThemeProvider from './themes/CustomThemeProvider'
import { Provider } from 'react-redux'

import "fontsource-roboto/300.css"
import "fontsource-roboto/400.css"
import "fontsource-roboto/500.css"
import "fontsource-roboto/700.css"
import 'react-toastify/dist/ReactToastify.min.css';

import store from './store'

document.title = "Santa Herba";
// When we have <React.StrictMode> we can change theme only once!
// Material-UI does not yet fully support StrictMode!
ReactDOM.render(
  <Provider store={store}>
    <CustomThemeProvider>
        <CssBaseline />
          <App />
      </CustomThemeProvider>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//registerServiceWorker();
