import normal from './normal'
import dark from './dark'
import carolina from './carolina'
import carolinaDark from './carolinaDark'

const themes = {
  normal,
  dark,
  carolina,
  carolinaDark
}

export default function getTheme(theme) {
  return themes[theme]
}
