import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

class LogoffComponent extends React.Component {
    constructor(props) {
        super();
        localStorage.setItem('token', '');
        window.location.href = '/login';
    };
    
    render() {
        return (<Grid container></Grid>);
    }
}

export default LogoffComponent;