import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CourseService from './courseService';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Preloader from './../partials/preloader';
import SmallPreloader from './../partials/smallPreloader';
import { Checkbox } from '@material-ui/core';
import parse from "html-react-parser";

class CoursePreShowComponent extends React.Component {
    constructor(props) {
        super();

        this.state = {
            isFirst: false,
            isAddQuestion: false,
            isEditQuestion: false,
            model: { id: 0,isPrivate:1, name: '', startDate: '', endDate: '', quantity: '', percentPass: '', price: '', description: '', place: '', users:[], items: [{ name: "Sekcja 1", items: [], files: [] }] },
            newQuestion: { items: [] },
            newFile: {},
            selectedSection: -1,
            isAddFile: false,
            isEditFile: false,
            selectedIndex: '',
            isEnd: false,
            isPass: false,
            result: 0,
            isDisable: true,
            isNotSave: false,
            isSaving: true,
            isSavingCount: false,
            hash: '',
        };

        this.payForm = React.createRef();

        if (props.match.params.id > 0)
            this.load(props.match.params.id);
    };

    setRadioAnswer = (value) => {
        let model = { ...this.state.model };
        model.isPrivate = value;
        this.setState({ model: model });
    };

    load = async (id) => {
        if (id > 0) {
            let item = await CourseService.getSimple(id,false);
            item.selectedIndex = 0;
            item.users = [];
            if (item.isMaxCount) {
                this.setState({isNotSave: true});
                item.items.forEach(function (d) {
                    d.items.forEach(function (e) {
                        let question = item.answers.filter(answer => answer.questionId === e.id);
                        if (question.length > 0 && question[0].answer!=null) {
                            e.answer = question[0].answer.toString();
                        }
                    });
                });
            }
            item.isPrivate = "1";
            this.setState({ model: item, isSaving: false, hash: item.hash });
            
        }
    }   

    clickBack = () => {
        window.history.back();
    };

    handleClose = () => {
        this.setState({ isEnd: false });
        if (this.state.isPass) {
            window.location.href = "/certsForUser";
        }
        else {
            window.location.href = "/coursesForUser";
        }
    };

    buy = async (event) => {
        if (!this.state.model.firstCheck || !this.state.model.thirdCheck) {
            event.preventDefault();
            this.setState({ validationMessage: "Zaznacz wymagane zgody" });
        }
        else if ((this.state.model.companyName == null || this.state.model.companyName == '') && this.state.model.isPrivate=="2") {
            event.preventDefault();
            this.setState({ validationMessage: "Pole nazwa firmy jest wymagane" });
        }
        else if ((this.state.model.firstName == null || this.state.model.firstName == '') && this.state.model.isPrivate == "1") {
            event.preventDefault();
            this.setState({ validationMessage: "Pole imię jest wymagane" });
        }
        else if ((this.state.model.lastName == null || this.state.model.lastName == '') && this.state.model.isPrivate == "1") {
            event.preventDefault();
            this.setState({ validationMessage: "Pole nazwisko jest wymagane" });
        }
        else if ((this.state.model.nip == null || this.state.model.nip == '') && this.state.model.isPrivate == "2") {
            event.preventDefault();
            this.setState({ validationMessage: "Pole nip jest wymagane" });
        }
        else if (this.state.model.address == null || this.state.model.address == '') {
            event.preventDefault();
            this.setState({ validationMessage: "Pole adres jest wymagane" });
        }
        else if (this.state.model.houseNumber == null || this.state.model.houseNumber == '') {
            event.preventDefault();
            this.setState({ validationMessage: "Pole numer domu jest wymagane" });
        }
        else if (this.state.model.postalCode == null || this.state.model.postalCode == '') {
            event.preventDefault();
            this.setState({ validationMessage: "Pole kod pocztowy jest wymagane" });
        }
        else if (this.state.model.city == null || this.state.model.city == '') {
            event.preventDefault();
            this.setState({ validationMessage: "Pole miejscowość jest wymagane" });
        }
        else {
            if (this.state.model.nip != null && this.state.model.nip != '' && !CourseService.checkNIP(this.state.model.nip)) {
                event.preventDefault();
                this.setState({ validationMessage: "Pole nip jest niepoprawne" });
                return;
            }

            event.preventDefault();
            let result = await CourseService.buyByCode(this.state.model.useDiscountName, this.state.model.price === 0, this.state.model.id, this.state.model.companyName, this.state.model.nip,
                this.state.model.firstName, this.state.model.lastName, this.state.model.address, this.state.model.houseNumber,
                this.state.model.flatNumber, this.state.model.postalCode, this.state.model.city, this.state.model.price);

            if (result !== null) {

                if (result.result !== 1) {
                    event.preventDefault();
                    return;
                }
                if (this.state.model.price === 0) {
                    window.location.reload();
                }
                this.payForm.current.submit();
                this.setState({ validationMessage: "" });
            }
        }
    };

    changeDiscountCode = async (event) => {
        event.preventDefault();
        let model = { ...this.state.model };
        let value = this.state.model.discountName;
        if (value !== '' && value !== undefined) {
            this.setState({ isSavingCount: true });
            let item = await CourseService.getSimpleByCode(model.id, value);
            if (item.error !== '' && item.error !== undefined) {
                this.setState({ model: model, validationMessage: "Wskazany kod jest nieaktywny" });
            }
            else {
                model.priceName = item.priceName;
                model.hash = item.hash;
                model.price = item.price;
                model.discountName = value;
                model.useDiscountName = value;
                this.setState({ model: model, hash: item.hash, validationMessage: null });
            }
            this.setState({ isSavingCount: false });
        }
        else {
            this.setState({ isSavingCount: true });
            let item = await CourseService.getSimple(model.id, false);
            model.priceName = item.priceName;
            model.hash = item.hash;
            model.price = item.price;
            model.discountName = value;
            model.useDiscountName = value;
            this.setState({ model: model, hash: item.hash, validationMessage: null });
            this.setState({ isSavingCount: false });
        }
    }

    render() {
        return (
            <div>
                { this.state.isSaving && <Preloader />}
                {!this.state.isSaving &&
                    <Grid container className="course-show course-user-list">
                        <Grid item xs={12} className="wrapper">
                            <div className="clear"></div>
                            <br />
                            <h1>
                                <a onClick={this.clickBack} className="bread"><i className="back"></i></a>Kursy - {this.state.model.name}
                            </h1>
                            <Container component="main" className="wrapper-main">
                                <Grid justify="center" container>
                                    <Grid item xs={12}>
                                        <Paper elevation={0} className="preshow fullWidth-mobile">
                                            <Grid container>
                                                <Grid item xs={4} className="center fullWidth-mobile">
                                                    <img src={this.state.model.mediaSrc} className="mt10" />
                                                </Grid>
                                                <Grid item xs={7} className="fullWidth-mobile padding-mobile">
                                                    <h5>{this.state.model.name}</h5>
                                                    <p>{parse(this.state.model.description)}</p>
                                                    <p className="brown-font">% wymagany do zdania: &gt;= <strong>{this.state.model.percentPass}</strong></p>
                                                </Grid>
                                            </Grid>
                                            {this.state.model.isAccess && (this.state.model.isMaxCount || this.state.model.isPass) &&
                                                <Grid container justify="center">
                                                    <p>
                                                        {this.state.model.isPass ?
                                                            <span className="blue-font">Kurs ukończony - GRATULACJE</span> :
                                                            <span className="blue-font">Niestety nie udało Ci się ukończyć kursu</span>
                                                        }
                                                    </p>
                                                    {this.state.model.isPass &&
                                                        <p>
                                                            <span className="blue-font">Twój wynik: {this.state.model.correctAnswer} %</span>
                                                        </p>
                                                    }
                                                    {!this.state.model.isPass &&
                                                        <p>
                                                            <span className="red-font">Twój wynik: {this.state.model.correctAnswer} %</span>
                                                        </p>
                                                    }
                                                </Grid>
                                            }
                                            {this.state.model.isAccess && !this.state.model.isMaxCount && this.state.model.quantityLeft < this.state.model.quantity && this.state.model.quantityLeft > 0 &&
                                                <Grid container justify="center">
                                                    <p>
                                                        <span className="blue-font">Kurs niezaliczony</span>
                                                    </p>
                                                    <p>
                                                        <span className="red-font">Twój wynik: {this.state.model.correctAnswer} - Pozostały Ci {this.state.model.quantityLeft} próby</span>
                                                    </p>
                                                </Grid>
                                            }
                                            <br />
                                            {this.state.model.isPass &&
                                                <Grid container justify="center">
                                                    <Link color="primary" to={"/certsForUser"} className="button">
                                                        Pobierz certyfikat
                                            </Link>
                                            &nbsp;
                                            <Link color="primary" to={"/courseShow/" + this.state.model.id} className="button">
                                                        Sprawdź szczegóły
                                            </Link>
                                                </Grid>
                                            }
                                            {this.state.model.isAccess && !this.state.model.isMaxCount && this.state.model.quantityLeft < this.state.model.quantity && this.state.model.quantityLeft > 0 &&
                                                <Grid container justify="center">
                                                    <Link color="primary" to={"/courseShow/" + this.state.model.id} className="button">
                                                        Rozpocznij ponownie
                                            </Link>
                                                </Grid>
                                            }
                                            {this.state.model.isAccess && !this.state.model.isMaxCount && this.state.model.quantityLeft === this.state.model.quantity && this.state.model.quantityLeft > 0 &&
                                                <Grid container justify="center">
                                                    <Link color="primary" to={"/courseShow/" + this.state.model.id} className="button">
                                                        Rozpocznij kurs
                                                    </Link>
                                                </Grid>
                                            }
                                            {(!this.state.model.isAccess || this.state.model.isMaxCount) && !this.state.model.isPass &&
                                                <Grid container>
                                                    <Grid container>
                                                        <Grid item xs={4} className="center not-mobile">
                                                            
                                                        </Grid>
                                                        <Grid item xs={7} className="fullWidth-mobile padding-mobile">
                                                        <table className="margin-zero-table fullWidth-mobile">
                                                                {this.state.validationMessage &&
                                                                    <tr>
                                                                        <td>
                                                                        </td>
                                                                        <td className="red-font-buy">
                                                                            {this.state.validationMessage}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                <tr>
                                                            <td>
                                                                <Checkbox onChange={(text) => this.state.model.firstCheck = text.target.checked} />
                                                                    </td>
                                                                    <td>
                                                                        Oświadczam, że zapoznałam/-em się i zrozumiałam/-em treść <a href="/images/regulamin.docx.pdf">Regulaminu</a> świadczenia usług drogą elektroniczną  oraz akceptuję wszystkie jego postanowienia. Rozumiem,że płatności za dostęp do treściaudiowizualnych na Platformie elektronicznej będą dokonywane za pośrednictwem zewnętrznego Operatora płatności.Oświadczam, że zapoznałam/-em się z warunkami świadczenia usług przez Operatora płatności i akceptuję wszystkie te warunki.(*)
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Checkbox onChange={(text) => this.state.model.thirdCheck = text.target.checked} />
                                                                    </td>
                                                                    <td>
                                                                        Oświadczam, że zapoznałam/-em się i zrozumiałam/-em treść <a href="/images/klauzula.docx.pdf">Klauzuli informacyjnej oraz Polityki cookies</a>. Wyrażam zgodę na przetwarzanie moich danych osobowych zamieszczonych przeze mnie w formularzu rejestracyjnym w celach określonych w Klauzuli informacyjnej oraz Polityce cookies. Zostałam/-em poinformowana/-y o tym, że podanie przeze mnie danych osobowych jest dobrowolne, ale niezbędne do zawarcia Umowy, a także o prawie dostępu do swoich danych, poprawiania ich oraz wycofania udzielonej zgody w dowolnym momencie oraz o pozostałych informacjach wynikających z art. 13 RODO. (*)
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className="mt10 ml-discount-10 fullWidth-mobile">
                                                        <Grid item xs={4} className="center not-mobile">

                                                        </Grid>
                                                        <Grid item xs={3} className="fullWidth-mobile padding-mobile">
                                                            <div className="mt10 radio fullWidth-mobile">
                                                                <RadioGroup defaultValue={this.state.model.isPrivate} className="width25" onChange={(text) => this.setRadioAnswer(text.target.value)}>
                                                                    <FormControlLabel value={"1"} control={<Radio />} label="Na osobę fizyczną" />
                                                                    <FormControlLabel value={"2"} control={<Radio />} label="Na firmę" />
                                                                </RadioGroup>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={3} className="not-mobile">
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className="mt10 ml-discount-10 fullWidth-mobile">
                                                        <Grid item xs={4} className="center not-mobile">

                                                        </Grid>
                                                        <Grid item xs={7} className="fullWidth-mobile padding-mobile">
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={2}>
                                                                        {this.state.model.isPrivate == "2" && <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField required label="Nazwa firmy" fullWidth value={this.state.model.companyName} onChange={(text) => { let model = { ...this.state.model }; model.companyName = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>}
                                                                        {this.state.model.isPrivate == "1" && <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField required label="Imię" fullWidth value={this.state.model.firstName} onChange={(text) => { let model = { ...this.state.model }; model.firstName = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>}
                                                                        {this.state.model.isPrivate == "1" && <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField required label="Nazwisko" fullWidth value={this.state.model.lastName} onChange={(text) => { let model = { ...this.state.model }; model.lastName = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>}
                                                                        <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField label="NIP" fullWidth value={this.state.model.nip} onChange={(text) => { let model = { ...this.state.model }; model.nip = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>
                                                                        <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField required label="Adres" fullWidth value={this.state.model.address} onChange={(text) => { let model = { ...this.state.model }; model.address = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>
                                                                        <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField required label="Nr domu" fullWidth value={this.state.model.houseNumber} onChange={(text) => { let model = { ...this.state.model }; model.houseNumber = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>
                                                                        <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField label="Nr lokalu" fullWidth value={this.state.model.flatNumber} onChange={(text) => { let model = { ...this.state.model }; model.flatNumber = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>
                                                                        <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField required label="Kod pocztowy" fullWidth value={this.state.model.postalCode} onChange={(text) => { let model = { ...this.state.model }; model.postalCode = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>
                                                                        <Grid item xs={4} className="mt10 fullWidth-mobile">
                                                                            <TextField required label="Miejscowość" fullWidth value={this.state.model.city} onChange={(text) => { let model = { ...this.state.model }; model.city = text.target.value; this.setState({ model: model }) }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className="mt10 ml-discount-10 fullWidth-mobile">
                                                        <Grid item xs={4} className="center no-mobile">

                                                        </Grid>
                                                        <Grid item xs={7} className="fullWidth-mobile padding-mobile">
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Grid container specing={2}>
                                                                        <Grid item xs={4} className="padding-8 fullWidth-55mobile">
                                                                            <TextField label="Wprowadź kod" defaultValue={this.state.model.discountName} fullWidth onChange={(text) => { this.state.model.discountName = text.target.value; }} />
                                                                        </Grid>
                                                                        <Grid item xs={4} className="fullWidth-50mobile">
                                                                            {this.state.isSavingCount && <SmallPreloader />}
                                                                            {!this.state.isSavingCount &&
                                                                                <button className="ml5 mt10a button" type="button" onClick={this.changeDiscountCode}>Przelicz</button>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                 </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={3} className="not-mobile">
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className="mt10 fullWidth-mobile">
                                                        <Grid item xs={4} className="center not-mobile">
                                                            
                                                        </Grid>
                                                        <Grid item xs={6} className="fullWidth-mobile padding-mobile">
                                                                {this.state.model.price !== 0 && 
                                                                    <form action="https://secure.pep.pl/order/cart.html" method="post" className="inline-buybutton" ref={this.payForm}>
                                                                        <input type="hidden" name="amount" value={this.state.model.priceName} />
                                                                        <input type="hidden" name="currency" value="PLN" />
                                                                        <input type="hidden" name="merchant_id" value="680776a400b5579314394adcfd3d53bb" />
                                                                        <input type="hidden" name="description" value={this.state.model.id + '_' + this.state.model.userId} />
                                                                        <input type="hidden" name="transaction_description" value={this.state.model.name} />
                                                                        <input type="hidden" name="transaction_type" value="S" />
                                                                        <input type="hidden" name="back_url" value={this.state.model.adressURL} />
                                                                        <input type="hidden" name="language" value="pl" />
                                                                        <input type="hidden" name="hash" value={this.state.hash} />
                                                                        <button className="button" type="submit" onClick={this.buy}>Wykup dostęp</button>
                                                                    </form>
                                                                }
                                                                {this.state.model.price === 0 && 
                                                                    <button className="button" type="submit" onClick={this.buy}>Wykup dostęp</button>
                                                                }
                                                                <span className="brown-font mt-5">&nbsp;&nbsp;cena: <strong>{this.state.model.priceName} zł</strong></span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <br />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

export default CoursePreShowComponent;