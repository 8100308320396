import React from 'react';
import axios from 'axios';

const config = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
}

class DiscountService extends React.Component {
    
    query = async (model) => {
        try {
            const { data } = await axios.post('api/discount/query', model, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    get = async (id) => {
        try {
            const { data } = await axios.get('api/discount/get?id=' + id, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    remove = async (id) => {
        try {
            const { data } = await axios.post('api/discount/remove', { id: id }, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    save = async (model) => {
        try {
            const { data } = await axios.post('api/discount/save', model, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }
}

export default new DiscountService();