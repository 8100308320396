import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#8eb2ff',
      main: '#5383ff',
      dark: '#0057cb',
      contrastText: '#fff',
    },
    secondary: {
      /*light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',*/
      light: '#6b74a6',
      main: '#3d4977',
      dark: '#0d224b',
      contrastText: '#fff',
    },
    error: {
      light: '#ff6d70',
      main: '#f83245',
      dark: '#bd001e',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffa85d',
      main: '#f4772e',
      dark: '#bb4800',
      contrastText: '#fff',
    },
    info: {
      light: '#68f8ff',
      main: '#11c5db',
      dark: '#0094a9',
      contrastText: '#fff',
    },
    success: {
      light: '#66fd73',
      main: '#1bc943',
      dark: '#009709',
      contrastText: '#fff',
    },
    text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)'
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
      // ZIndex from our components?
    },
    appBarColor: {
      main: '#3d4977',
    },
  },

  overrides: {
    MuiTableRow: {
      root: {
        '&$selected': { // <-- mixing the two classes
          backgroundColor: '#E18989'
        },
        '&$selected:hover': { // <-- mixing the two classes
          backgroundColor: '#E18989'
        },
      }
    }
  },
  focusedWorkerFilter: {
    backgroundColor: '#6b74a6',
  },
})

export default theme
