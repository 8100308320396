import React from 'react';
import Grid from '@material-ui/core/Grid';
import UserService from './userService';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Preloader from './../partials/preloader';

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Imię' },
    { id: 'surname', numeric: false, disablePadding: false, label: 'Nazwisko' },
    { id: 'createdDate', numeric: true, disablePadding: false, label: 'Data dołączenia' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Adres e-mail' },
    { id: 'sex', numeric: false, disablePadding: false, label: 'Płeć' },
    { id: 'birthDate', numeric: true, disablePadding: false, label: 'Data urodzenia' },
    { id: 'newsletter', numeric: false, disablePadding: false, label: 'Newsletter' },
    { id: 'coursesName', numeric: false, disablePadding: false, label: 'Kursy' },
    { id: 'action', numeric: false, disableSorting: true, label: 'Akcje' },
];

class UsersComponent extends React.Component {

    constructor(props) {
        super();

        this.state = {
            order: 'asc',
            orderBy: 'calories',
            selected: [],
            page: 1,
            dense: false,
            rowsPerPage: 10,
            search: '',
            rows: [],
            isFilter: false,
            name: '',
            surname: '',
            email: '',
            birthDateFrom: null,
            birthDateTo: null,
            size: 0,
            isLoading: true,
        };

        if (localStorage.getItem('role') != '0') {
            window.location.href = "/coursesForUser";
        }
        else {
            this.setState({ emptyRows: this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage) })
            this.refreshItems();
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({ order: 'asc' });
        this.setState({ orderBy: property });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.rows.map((n) => n.id);
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, name) => {
        const selectedIndex = this.state.selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    refreshItems = async () => {
        let users = await UserService.query({ order: (this.state.order == 'desc' ? '-' : '') + this.state.orderBy, limit: 10, start: (this.state.page - 1) * 10, end: (this.state.page - 1) * 10 + 10, page: this.state.page, search: this.state.search, name: this.state.name, surname: this.state.surname, email: this.state.email, birthDateFrom: this.state.birthDateFrom, birthDateTo: this.state.birthDateTo });
        this.setState({ rows: users.items, size: users.length });
    }

    handleChangePage = (event, newPage) => {
        this.state.page = newPage + 1;
        this.setState({ page: newPage + 1 });
        this.refreshItems();
    };

    remove = async (id) => {
        if (window.confirm('Czy na pewno chcesz usunąć użytkownika?')) {
            await UserService.remove(id);
            this.refreshItems();   
        }
    };

    exportCSV = async () => {
        this.setState({isLoading: false});
        let file = await UserService.exportCSV();
        if (file !== null) {
            this.setState({ isLoading: true });
            window.open(file.fileName, '_blank');
        }
    };

    exportPDF = async () => {
        this.setState({ isLoading: false });
        let file = await UserService.exportPDF();
        if (file !== null) {
            this.setState({ isLoading: true });
            window.open(file.fileName, '_blank');
        }
    };

    removeUsers = async () => {
        if (this.state.selected.length > 0) {
            if (window.confirm('Czy na pewno chcesz usunąć użytkowników?')) {
                for (let i = 0; i < this.state.selected.length; i++) {
                    await UserService.remove(this.state.selected[i]);
                }
                this.refreshItems();
            }
        }
    };

    showFilter = async () => {
        this.setState({ isFilter: !this.state.isFilter })
    };


    block = async (id) => {
        if (window.confirm('Czy na pewno chcesz zablokować użytkownika?')) {
            await UserService.block(id);
            this.refreshItems();
        }
    };

    blockUsers = async () => {
        if (this.state.selected.length > 0) {
            if (window.confirm('Czy na pewno chcesz zablokować użytkowników?')) {
                for (let i = 0; i < this.state.selected.length; i++) {
                    await UserService.block(this.state.selected[i]);
                }
                this.refreshItems();
            }
        }
    };

    onSelectAllClick = () => {
        let selected = [];
        for (let i = 0; i < this.state.rows.length; i++) {
            selected.push(this.state.rows[i].id);
        }
        this.setState(selected);
    };

    createSortHandler = (columnId) => {
        if (columnId != 'action') {
            this.state.order = columnId == this.state.orderBy && this.state.order != 'desc' ? 'desc' : 'asc';
            this.state.orderBy = columnId;
            this.setState({ orderBy: columnId });
            this.refreshItems();
        }
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };

    clearFilter = () => {
        this.setState({
            name: '',
            surname: '',
            email: '',
            birthDateFrom: null,
            birthDateTo: null
        });
        this.state.name = '';
        this.state.surname = '';
        this.state.email = '';
        this.state.birthDateFrom = null;
        this.state.birthDateTo = null;
        this.refreshItems();
    }

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    render() {
        return (
            <div>
            {!this.state.isLoading && <Preloader /> }
                {this.state.isLoading &&
                    <Grid container>
                        <Grid item xs={12} className="wrapper">
                            <div className="border-bottom">
                                <Grid container>
                                    <Grid item xs={10} item>
                                        <h1 className="no-border">

                                        </h1>
                                    </Grid>
                                    <Grid item xs={1} item>
                                        <a className="blue-font2 not-mobile float-right" onClick={(event) => this.exportPDF()}>Eksportuj PDF</a>
                                    </Grid>
                                    <Grid item xs={1} item>
                                        <a className="blue-font2 not-mobile float-right" onClick={(event) => this.exportCSV()}>Eksportuj CSV</a>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={2} item>
                                        <h1 className="no-border">
                                            Lista użytkowników
                                        </h1>
                                    </Grid>
                                    <Grid item xs={8} item className="filter">
                                        <Link className="blue-font2 add-user" to="/userAdd/0"><i className="playlist"></i>&nbsp;Dodaj nowego użytkownika</Link>
                                        <a className="gray-font2 not-mobile" not-mobile>Akcje grupowe: </a>
                                        <a className="blue-font2 not-mobile" onClick={(event) => this.blockUsers()}><i className="block"></i>&nbsp;Blokuj</a>
                                        <a className="blue-font2 not-mobile" onClick={(event) => this.removeUsers()}><i className="remove"></i>&nbsp;Usuń</a>
                                        <a className="gray-font2 not-mobile">Filtry: </a>
                                        {!this.state.isFilter &&
                                            <a className="blue-font2 not-mobile users-filter"><i className="search"></i>&nbsp;<TextField label="Szukaj" className="searchInput" onChange={(text) => { this.state.search = text.target.value; this.refreshItems(); }} /></a>
                                        }
                                    </Grid>
                                    <Grid item xs={2} item>
                                        <a className="blue-font2 not-mobile float-right no-margin-left" onClick={(event) => this.showFilter()}><i className="filter-icon"></i>&nbsp;Pokaż wszystkie filtry</a>
                                    </Grid>
                                </Grid>
                                {this.state.isFilter &&
                                    <div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="Imię" value={this.state.name} onChange={(text) => { this.setState({ name: text.target.value }); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="Nazwisko" value={this.state.surname} onChange={(text) => { this.setState({ surname: text.target.value }); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="Email" value={this.state.email} onChange={(text) => { this.setState({ email: text.target.value }); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                                    <DatePicker label="Data urodzenia" format="dd-MM-yyyy" fullWidth value={this.state.birthDateFrom} onChange={(text) => { this.state.birthDateFrom = text; this.refreshItems(); }} />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                                    <DatePicker label="do" format="dd-MM-yyyy" fullWidth value={this.state.birthDateTo} onChange={(text) => { this.state.birthDateTo = text; this.refreshItems(); }} />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={2} item>
                                                <a onClick={(event) => this.clearFilter()} className="blue-font2 not-mobile float-right no-margin-top"><i className="block"></i>&nbsp;Wyczyść filtr</a>
                                            </Grid>
                                        </Grid>
                                        <div className="space"></div>
                                        <br />
                                        <br />
                                    </div>
                                }
                            </div>
                            <div className="space"></div>
                            <div>
                                <Paper>
                                    <TableContainer>
                                        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.rows.length}
                                                            checked={this.state.rows.length > 0 && this.state.selected.length === this.state.rows.length}
                                                            onChange={this.handleSelectAllClick}
                                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                                        />
                                                    </TableCell>
                                                    {headCells.map((headCell) => (
                                                        <TableCell
                                                            key={headCell.id}
                                                            align={headCell.numeric ? 'center' : 'left'}
                                                            padding={headCell.disablePadding ? 'none' : 'default'}
                                                            sortDirection={this.state.order}
                                                            onClick={(event) => this.createSortHandler(headCell.id)}
                                                        >
                                                            {!headCell.disableSorting && <TableSortLabel
                                                                active={this.state.orderBy === headCell.id}
                                                                direction={this.state.order}
                                                            >
                                                                {headCell.label}
                                                            </TableSortLabel>}
                                                            {headCell.disableSorting &&
                                                                <span>{headCell.label}</span>}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.rows.map((row, index) => {
                                                    const isItemSelected = this.isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox" onClick={(event) => this.handleClick(event, row.id)}>
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.surname}</TableCell>
                                                            <TableCell align="center">{row.createdDate}</TableCell>
                                                            <TableCell align="left">{row.email}</TableCell>
                                                            <TableCell align="left">{row.sex}</TableCell>
                                                            <TableCell align="center">{row.birthDate}</TableCell>
                                                            <TableCell align="center">{row.isNewsletter ? 'TAK' : 'NIE'}</TableCell>
                                                            <TableCell align="left">{row.coursesName}</TableCell>
                                                            <TableCell align="left" className="tableFixedWidth"><Link className="create" to={"/userAdd/" + row.id}></Link>&nbsp;&nbsp;&nbsp;

                                                            {row.status != 'Zablokowany' &&
                                                                    <i className="block" onClick={(event) => this.block(row.id)}></i>
                                                            }

                                                            {row.status === 'Zablokowany' &&
                                                                <i className="check" onClick={(event) => this.block(row.id)}></i>
                                                            }

                                                            &nbsp;&nbsp;&nbsp;<i className="remove" onClick={(event) => this.remove(row.id)}></i>&nbsp;</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {this.state.emptyRows > 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        count={this.state.size}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page - 1}
                                        onChangePage={this.handleChangePage}
                                    />
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

export default UsersComponent;