import React from 'react';
import Grid from '@material-ui/core/Grid';
import CourseService from './courseService';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Link } from 'react-router-dom';
import Preloader from './../partials/preloader';
import parse from "html-react-parser";

class CoursesForUserComponent extends React.Component {

    constructor(props) {
        super();

        this.state = {
            order: 'asc',
            orderBy: 'calories',
            selected: [],
            page: 1,
            dense: false,
            rowsPerPage: 100,
            search: '',
            rows: [],
            order: 10,
        };
        this.setState({ emptyRows: this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage), isLoading: false })
        this.refreshItems();

        if (localStorage.getItem('role') === '0') {
            window.location.href = "/courses";
        }
    }

    refreshItems = async () => {
        let orderBy = '-id';
        if (this.state.order == 0) {
            orderBy = 'name';
        }
        else if (this.state.order == 1) {
            orderBy = '-name';
        }
        else if (this.state.order == 2) {
            orderBy = 'price';
        }
        else if (this.state.order == 3) {
            orderBy = '-price';
        }
        else if (this.state.order == 4) {
            orderBy = 'createdDate';
        }
        else if (this.state.order == 5) {
            orderBy = '-createdDate';
        }
        else if (this.state.order == 6) {
            orderBy = 'startDate';
        }
        else if (this.state.order == 7) {
            orderBy = '-startDate';
        }
        else if (this.state.order == 8) {
            orderBy = 'endDate';
        }
        else if (this.state.order == 9) {
            orderBy = '-endDate';
        }
        else if (this.state.order == 10) {
            orderBy = '-id';
        }
        let certs = await CourseService.query({ order: orderBy, limit: 30, start: (this.state.page - 1) * 20, end: (this.state.page - 1) * 30 + 30, page: this.state.page, search: this.state.search });
        this.setState({ rows: certs.items, isLoading: true });
    }

    render() {
        return (
            <div>
            {!this.state.isLoading && <Preloader /> }
                {this.state.isLoading &&
                    <Grid container className="course-user-list">
                        <Grid item xs={12} className="wrapper">
                            <div className="border-bottom">
                                <Grid container spacing={3}>
                                    <Grid item xs={5} item>
                                        <h1 className="no-border">
                                            Kursy
                                </h1>
                                    </Grid>
                                    <Grid item xs={5} item className="filter">
                                        <a className="blue-font2"><i className="search"></i>&nbsp;<TextField label="Szukaj" className="searchInput" onChange={(text) => { this.state.search = text.target.value; this.refreshItems(); }} /></a>
                                    </Grid>
                                    <Grid item xs={2} item>
                                        <FormControl variant="outlined" fullWidth className="sort-select">
                                            <InputLabel htmlFor="outlined-status-native-simple">Sortuj</InputLabel>
                                            <Select native label="Sortuj" value={this.state.order} inputProps={{ name: 'sort', id: 'outlined-status-native-simple' }} onChange={(text) => { this.state.order = parseInt(text.target.value); this.setState({ order: this.state.order }); this.refreshItems(); }}>
                                                <option value={0}>Nazwa od A do Z</option>
                                                <option value={1}>Nazwa od Z do A</option>
                                                <option value={2}>Cena (rosnąco)</option>
                                                <option value={3}>Cena (malejąco)</option>
                                                <option value={4}>Data dodania (rosnąco)</option>
                                                <option value={5}>Data dodania (malejąco)</option>
                                                <option value={6}>Data rozpoczęcia (rosnąco)</option>
                                                <option value={7}>Data rozpoczęcia (malejąco)</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <div className="space"></div>
                        <Grid container className="wrapper courses-list">
                            {this.state.rows.map((row, index) => {
                                return (
                                    <Grid item xs={6} spacing={3} className="padding">
                                        <Paper elevation={0}>
                                            <Grid container>
                                                <Grid item xs={3} className="center">
                                                    <img src={row.mediaSrc} className="mt10" />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <div style={{ height: 150, overflowY: 'hidden' }}>
                                                        {row.isPass && <i className="smile"></i>}
                                                        {!row.isPass && row.isMaxCount && <i className="notsmile"></i>}
                                                        <p className="more-font"><strong>{row.name}</strong>
                                                            {row.isAccess && row.percent != null &&
                                                                <span> - Wynik: <strong>{row.percent} %</strong></span>
                                                            }
                                                        </p>
                                                        <p className="gray-color" style={{ maxHeight: 40, overflowY: 'hidden' }}>{parse(row.description)}</p>
                                                    </div>
                                                    <p>
                                                        {!row.isAccess &&
                                                            <span className="gray-color">cena: <strong>{row.price} zł</strong></span>
                                                        }
                                                    </p>
                                                    <p>
                                                        {!row.isAccess && <Link color="primary" to={"/coursePreShow/" + row.id} className="blue-font left">
                                                            Sprawdź szczegóły&nbsp;<i className="idetails"></i>
                                                        </Link>
                                                        }
                                                        {row.isAccess && !row.isPass && !row.isMaxCount && <Link color="primary" to={"/coursePreShow/" + row.id} className="button">
                                                            Rozpocznij
                                                        </Link>
                                                        }
                                                        <div className="clear"></div>
                                                        {row.isAccess && (row.isMaxCount || row.isPass) &&
                                                            <Link to={"/coursePreShow/" + row.id} className="blue-font left">Sprawdź szczegóły&nbsp;<i className="idetails"></i></Link>
                                                        }
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            }
                            )}
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

export default CoursesForUserComponent;