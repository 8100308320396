import React from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player'

class FileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: '',
            type: this.props.type,
            newFile: this.props.newFile,
            modelFile: this.props.modelFile,
        };
    }

    setFile = async(e) => {
        this.setState({ file: e.target.files[0] });

        let url = 'api/users/users/uploadFile';

        if(this.state.type==='cert')
            url = 'api/users/certs/uploadFile';
        else if (this.state.type === 'media')
            url = 'api/users/media/uploadFile';
        else if (this.state.type === 'file')
            url = 'api/users/file/uploadFile';

        const formData = new FormData();
        formData.append('body', e.target.files[0]);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        const HTTP = axios.create({
            withCredentials: true
        });

        //return post(url, formData, config);
        let result = await HTTP.post(url, formData, config);
        
        this.setState({ fileName: result.data.fileName })
        
        if (this.state.newFile != null) {
            this.state.newFile.setFileSrc(result.data.fileName, result.data.resultFile );
        }

        if (this.state.type === 'cert') {
            this.state.modelFile.setFileCertSrc(result.data.fileName,result.data.resultFile);
        }

        if (this.state.type === 'media') {
            this.state.modelFile.setFileMediaSrc(result.data.fileName,result.data.resultFile);
        }

        if (this.state.type === 'user') {
            this.state.modelFile.setFileMediaSrc();
        }
    }

    componentDidMount = () => {
        if (this.state.type === 'cert')
            this.setState({ fileName: this.state.modelFile.getFileCertSrc() })
        else if (this.state.type === 'media')
            this.setState({ fileName: this.state.modelFile.getFileMediaSrc() })
        else if (this.state.type === 'user')
            this.setState({ fileName: this.state.modelFile.getFileMediaSrc() })
    }

    render() {
        return (
            <div>
                <input type="file" onChange={e => this.setFile(e)} /><br/>
                <div className="clear"></div>
                {this.state.fileName != null && this.state.fileName != '' && this.state.fileName.indexOf('/png') <= 0 && this.state.fileName.indexOf('http://') < 0 &&
                    <video className="avatar-form" controls>
                        <source src={this.state.fileName} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                }
            </div>
        )
    }
}
export default FileUpload;