  
import React, {useEffect } from 'react';
import { isAuthenticated, getLogesAs, getBearer, getTestAuth } from '../login/authService';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { testAuthorizeAttribute } from '../login/authService';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export function InfoComponent(props) {
  const classes = useStyles();

  useEffect(() => {
    if(props.isAuthenticated === false)
    {
      const { clearRouting } = props;
      clearRouting();
    }
    else
    {
      if(props.routes && props.routes.length === 0)
      {
        const { getRouting } = props;
        getRouting();
      }
    }
  }, [props.isAuthenticated]);

  return (
    <div className={classes.root}>
        <div>
              <Grid container>
                  <Grid item xs={12} className="wrapper">
                      <div className="border-bottom">
                          <Grid container spacing={3}>
                              <Grid item xs={2} item>
                                  <h1 className="no-border">
                                      Informacja
                                  </h1>
                              </Grid>
                          </Grid>
                      </div>
                      <div>
                          <Paper className="padding">
                              <p><a href="/images/regulamin.docx.pdf">1. Regulamin</a></p>
                              <p><a href="/images/klauzula.docx.pdf">2. Klauzula informacyjna</a></p>
                              <p><a href="/images/informacja.docx.pdf">3. Informacja o zagrożeniach</a></p>
                              <p><a href="/images/informacjaUslugodawca.docx.pdf">4. Informacje o usługodawcy</a></p>
                              <p>Copyright © 2021 Coblit. All rights reserved. Wersja: 1.0.0</p>
                          </Paper>
                      </div>
                  </Grid>
              </Grid>
        </div>
    </div>
  );
}

const mapStateToProps = state => ({
    isAuthenticated: true,
})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoComponent);