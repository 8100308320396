import React from 'react';
import axios from 'axios';

const config = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
}

class CertService extends React.Component {
    
    query = async (model) => {
        try {
            const { data } = await axios.post('api/cert/query', model, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    download = async (model) => {
        try {
            const { data } = await axios.post('api/cert/download', model);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    downloadInvoice = async (model) => {
        try {
            const { data } = await axios.post('api/cert/downloadInvoice', model);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    remove = async (id) => {
        try {
            const { data } = await axios.post('api/cert/remove', { id: id });
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }
}

export default new CertService();