import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import AuthService from './authService';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

class ActivateComponent extends React.Component {

    constructor(props) {
        super();

        this.state = {
            validationMessage: "",
            model: { birthDate: null, activateLink: props.match.params.id, sex: 0 },
            showPassword: false,
            showPassword2: false,
        };
    }

    handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
    handleMouseDownPassword = () => this.setState({ showPassword: !this.state.showPassword });

    handleClickShowPassword2 = () => this.setState({ showPassword2: !this.state.showPassword2 });
    handleMouseDownPassword2 = () => this.setState({ showPassword2: !this.state.showPassword2 });

    register = async (event) => {
        event.preventDefault();
        let regularExpression = new RegExp("^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
        if ((this.state.model.password.length < 7 || this.state.model.password.length > 15) && !regularExpression.test(this.state.model.password)) {
            this.setState({ validationMessage: "Hasło musi zawierać min 7 max 15, znak specjalny (~ ! @ # $ % ^ & * ( ) _ - + = { } [ ] \ | : ; < > , . ? /" });
            return false;
        }
        
        if (this.state.model.password != this.state.model.password2) {
            this.setState({ validationMessage: "Pole hasło i powtórz hasło muszą być takie same" });
            return false;
        }
        
        let user = await AuthService.activate(this.state.model);
        if (user.error != null && user.error != '') {
            this.setState({ validationMessage: user.error });
        }
        else {
            localStorage.setItem('token', user.token);
            if (user.role == 0)
                window.location.href = "/";
            else if (user.role == 1)
                window.location.href = "/coursesForUser";
        }
    };

    render() {
        return (
            <Grid container className="login-grid">
                <Grid item xs={9}>
                    <div className="login-background not-mobile">
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Container component="main" maxWidth="xs">
                        <a className="logo" href="/">
                        </a>
                        <div>
                            <form className="login-form" validate="true" autoComplete="off" onSubmit={(event) => this.register(event)}>
                                <div className="validation-error">{this.state.validationMessage}</div>
                                <h1>
                                    Aktywacja konta
                                </h1>
                                <Grid container spacing={3}>
                                    <div className="space"></div>
                                    <Grid item xs={12} item>
                                        <TextField required label="Hasło" fullWidth type={this.state.showPassword ? "text" : "password"} InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                        onMouseDown={this.handleMouseDownPassword}
                                                    >
                                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }} onChange={(text) => this.state.model.password = text.target.value} />
                                    </Grid>
                                    <Grid item xs={12} item>
                                        <TextField required label="Powtórz hasło" fullWidth type="password" onChange={(text) => this.state.model.password2 = text.target.value} type={this.state.showPassword2 ? "text" : "password"} InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword2}
                                                        onMouseDown={this.handleMouseDownPassword2}
                                                    >
                                                        {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}/>
                                    </Grid>
                                </Grid>
                                <div className="space"></div>
                                <Button type="submit" fullWidth color="primary">
                                    Aktywuj
                                </Button>
                            </form>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        )
    }
}

export default ActivateComponent;