import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import AuthService from './authService';
import { Link } from 'react-router-dom'; 

class LoginComponent extends React.Component {

    constructor(props) {
        super();
        this.state = {
            validationMessage: "",
            model: {},
        };
    }

    login = async () => {
        let user = await AuthService.login(this.state.model)        
        if (user.error != null && user.error != '') {
            this.setState({ validationMessage: user.error });
        }
        else {
            localStorage.setItem('token', user.token);
            if (user.role==0)
                window.location.href = "/";
            else if (user.role == 1)
                window.location.href = "/coursesForUser";
        }
    };

    render() {
        return (
            <Grid container className="login-grid">
                <Grid item xs={9} className="not-mobile">
                    <div className="login-background">
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Container component="main" maxWidth="xs">
                        <div className="logo">
                        </div>
                        <div>
                            <form className="login-form" validate="true" autoComplete="off" >
                                <div className="validation-error">{this.state.validationMessage}</div>
                                <h1>
                                    Zaloguj
                                </h1>
                                <TextField required label="Login" fullWidth autoFocus onChange={(text) => this.state.model.login = text.target.value} />
                                <div className="space"></div>
                                <TextField required fullWidth label="Hasło" type="password" onChange={(text) => this.state.model.password = text.target.value} />
                                <div className="space"></div>
                                <Link to="/forgot">
                                    Nie pamiętasz hasła?
                                </Link><br />
                                <a href="/images/regulamin.docx.pdf" target="_blank">
                                    Regulamin
                                </a><br/>
                                <a href="/images/klauzula.docx.pdf" target="_blank">
                                    Klauzula informacyjna
                                </a><br />
                                <a href="/images/informacja.docx.pdf" target="_blank">
                                    Informacja o zagrożeniach
                                </a>
                                <div className="space"></div>
                                <Button fullWidth color="primary" onClick={this.login}>
                                    Zaloguj
                                </Button>
                                <div className="space"></div>
                                <Link to="/register" className="blue-font">
                                    Zarejestruj
                                </Link>
                                <div className="space"></div>
                            </form>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        )
    }
}

export default LoginComponent;