import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CourseService from './courseService';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactPlayer from 'react-player'

class CourseResultShowComponent extends React.Component {
    constructor(props) {
        super();

        this.state = {
            isFirst: false,
            isAddQuestion: false,
            isEditQuestion: false,
            model: { id: 0, name: '', startDate: '', endDate: '', quantity: '', percentPass: '', price: '', description: '', place: '', users:[], items: [{ name: "Sekcja 1", items: [], files: [] }] },
            newQuestion: { items: [] },
            newFile: {},
            selectedSection: -1,
            isAddFile: false,
            isEditFile: false,
            selectedIndex: '',
            isEnd: false,
            isPass: false,
            result: 0,
            isDisable: true,
            isNotSave: false,
        };

        if (props.match.params.id > 0)
            this.load(props.match.params.id, props.match.params.userId);
    };

    setRadioAnswer = (section, question, value) => {
        let model = { ...this.state.model };
        let questionIndex = model.items[model.items.indexOf(section)].items.indexOf(question);
        model.items[model.items.indexOf(section)].items[questionIndex].answer = parseInt(value);
        this.setState({ isDisable: false });        
    };

    load = async (id, userId) => {
        if (id > 0) {
            let item = await CourseService.getForUser(id, true, userId);
            item.selectedIndex = 0;
            item.users = [];
            this.setState({isNotSave: true});
            item.items.forEach(function (d) {   
                d.items.forEach(function (e) {
                    let question = item.answers.filter(answer => answer.questionId === e.id);
                    if (question.length > 0) {
                        e.answer = question[0].answer.toString();
                    }
                });
            });
            this.setState({ model: item });
        }
    }

    showSections = () => {
        this.setState({ isFirst: true });
    };

    back= () => {
        this.setState({ isFirst: false });
    };

    clickClose = async () => {
        if (this.state.isDisable && !this.state.isNotSave) {
            alert('Aby zakończyć kurs, musisz zaznaczyć odpowiedź!');
            return;
        }
        this.state.model.answers = null;
        window.history.back();
    };

    clickBack = () => {
        window.history.back();
    };

    clickNext = () => {
        if (this.state.isDisable && !this.state.isNotSave) {
            alert('Aby przejść do następnego pytania, musisz zaznaczyć odpowiedź!');
            return;
        }

        let model = { ...this.state.model };
        if(model.selectedIndex+1<model.items.length)
            model.selectedIndex++;
        this.setState({ model: model, isDisable: true });
    };

    clickPrev = () => {
        let model = { ...this.state.model };
        if (model.selectedIndex > 0)
            model.selectedIndex--;
        this.setState({ model: model });
    };

    handleClose = () => {
        this.setState({ isEnd: false });
        if (this.state.isPass) {
            window.location.href = "/certsForUser";
        }
        else {
            window.location.href = "/coursesForUser";
        }
    };

    render() {
        return (
                <Grid container className="course-show">
                    <Grid item xs={12} className="wrapper">
                        <div className="clear"></div>
                        <br />
                        <h1>
                            <a onClick={this.clickBack} className="bread"><i className="back"></i></a>Kursy - {this.state.model.name}
                        </h1>
                        <Container component="main" className="wrapper-main">
                        <Grid container>                            
                            {this.state.model.items.map((prop, key) => {
                                return (
                                    <div className="fullWidth">
                                        {this.state.model.items.indexOf(prop) === this.state.model.selectedIndex &&
                                            <Grid container>
                                                <Grid item xs={6} item className="border-right">
                                                    <div className="right-box">
                                                        {prop.files.map((file, key) => {
                                                            return (
                                                                <Grid container>
                                                                    <Grid item xs={12} item>
                                                                        {file.src != null && file.src != '' && file.src.indexOf("image/png") > 0 && <img src={file.src} className="max-imagewidth" width="104%" height="400px" />}  
                                                                        {file.src != null && file.src != '' && file.src.indexOf("image/png") <= 0 &&
                                                                            <video src={file.src} className="max-imagewidth" controls width="100%" height="400px"/>
                                                                        }
                                                                        {file.youtube != null && file.youtube != '' && <ReactPlayer className="max-imagewidth" url={file.youtube} width="104%" height="400px" controls={true}/>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12} item>
                                                                        <p>{file.description}</p>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                        <div className="space"></div>
                                                    </div>
                                                    <div className="space"></div>
                                                </Grid>
                                                <Grid item xs={6} item>
                                                    {prop.items.map((question, key) => {
                                                        return (
                                                            <div className="left-align ml-10">
                                                                <div>
                                                                    <Grid container>
                                                                        <Grid item xs={1}>
                                                                            <span className="gray-font">Pytanie </span>
                                                                        </Grid>
                                                                        <Grid item xs={11}>
                                                                            {question.name}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div className="mt10">
                                                                    <RadioGroup onChange={(text) => this.setRadioAnswer(prop, question, text.target.value)} defaultValue={question.answer}>
                                                                        {question.items.map((answer, key) => {
                                                                            return (
                                                                                <FormControlLabel value={answer.id.toString()} control={<Radio />} label={answer.name} disabled={this.state.isNotSave} className={this.state.isNotSave ? (question.answer==answer.id && !answer.isCorrect ? 'red-radio': (answer.isCorrect ? 'green-radio':'')):''}/>
                                                                            )
                                                                        })}
                                                                    </RadioGroup>
                                                                </div>
                                                                <div className="space"></div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="space"></div>
                                                    <div className="border-top ml-2"></div>
                                                </Grid>
                                            </Grid>
                                        }
                                    </div>
                                )
                            })}
                        </Grid>
                        <div className="space"></div>
                        <Grid container className="border-top">
                            <Grid item xs={2} item>
                                <Button color="primary" onClick={this.clickPrev}>
                                    Poprzednie
                                </Button>
                            </Grid>
                            <Grid item xs={9} item>
                                {this.state.model.items.map((prop, key) => {
                                    return (
                                        <div>
                                            { this.state.model.selectedIndex == this.state.model.items.indexOf(prop) ? <a className="question selected" > {this.state.model.items.indexOf(prop) + 1}</a> : <a className="question" > {this.state.model.items.indexOf(prop) + 1}</a> }
                                        </div>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={1} item className="right">
                                {this.state.model.selectedIndex == this.state.model.items.length-1 ?
                                    <Button color="primary" onClick={this.clickClose}>
                                        Zakończ
                                     </Button> :
                                    <Button color="primary" onClick={this.clickNext}>
                                        Następne
                                     </Button>
                               }
                            </Grid>
                        </Grid>
                        </Container>
                </Grid>
                <Dialog open={this.state.isEnd} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth='xl'>
                    <DialogTitle id="form-dialog-title">Wynik</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12} spacing={3} className="padding">
                            <Paper elevation={0}>
                                <Grid container>
                                    <Grid item xs={4} className="center">
                                        <img src={this.state.model.mediaSrc} className="mt10" />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <h5>{this.state.model.name}</h5>
                                        <p>
                                            {this.state.isPass ?
                                                <div><p>Kurs ukończony - GRATULACJE,</p><p className="blue-font left">Twój wynik: {this.state.result} %</p></div> :
                                                <div><p>Niestety nie udało Ci się ukończyć kursu: {this.state.model.name}.</p><p className="blue-font left">Twój wynik: {this.state.result} %</p><p>Z całego serca wierzymy że następnym razem pójdzie Ci lepiej :)</p></div>
                                            }
                                        </p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className="center width100">
                            {this.state.isPass ?
                                <Button onClick={this.handleClose} color="primary">
                                    Pobierz certyfikat
                                 </Button> : <Button onClick={this.handleClose} color="primary">
                                    Przejdź do kursów
                                 </Button>
                            }
                        </div>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

export default CourseResultShowComponent;