import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import UserService from './userService';
import FileUpload from './../partials/fileUpload';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import format from "date-fns/format";
import { Checkbox } from '@material-ui/core';

class UserAddComponent extends React.Component {

    constructor(props) {
        super();
        let maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        this.state = {
            validationMessage: "",
            model: { id: 0, name: '', surname: '', email: '', postalCode: '', phoneNumber: '', position: '', password: '', password2: '', birthDate: null, userStatus: 1, isNewsletter: false },
            roleId: localStorage.getItem('role'),
            maxDate: format(maxDate, 'yyyy-MM-dd'),
            isMyProfile: localStorage.getItem('role') != '0',
        };

        this.load(props.match.params.id);
    }

    load = async (id) => {
        
        if (id > 0 || localStorage.getItem('role') === '1') {
            let user = await UserService.get(id);
            this.setState({ model: user });
        }
    }

    save = async (event) => {
        event.preventDefault();
        debugger;
        if (this.state.model.birthDate == null) {
            this.setState({ validationMessage: "Pole data urodzenia jest wymagane" });
            return;
        }

        if (this.state.model.password!=null && this.state.model.password != this.state.model.password2) {
            this.setState({ validationMessage: "Pole hasło i powtórz hasło muszą być takie same" });
            return false;
        }

        let user = await UserService.save(this.state.model);
        if (user.error != null && user.error != '') {
            this.setState({ validationMessage: user.error });
            return;
        }
        else {
            if (localStorage.getItem('role') != '0') {
                localStorage.setItem('avatar', user.avatar != null ? user.avatar : '');
                localStorage.setItem('fullName', user.fullName != null ? user.fullName : '');
                window.location.href = "/coursesForUser";
            }
            else
                window.location.href = "/";
        }
    };

    delete = async (event) => {
        event.preventDefault();

        if (window.confirm('Czy na pewno chcesz usunąć konto?')) {
            await UserService.remove(0);
            window.location.href = "/logoff";
        }
    };

    getFileMediaSrc = () => {
        return this.state.model.src;
    }

    setFileMediaSrc = () => {
        let model = { ...this.state.model };
        model.src = null;
        this.setState({ model: model });
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="wrapper">
                    {this.state.model.id <= 0 && !this.state.isMyProfile && <h1>
                        Dodaj nowego użytkownika
                    </h1>}
                    {this.state.model.id > 0 && !this.state.isMyProfile && <h1>
                        Edycja użytkownika
                    </h1>}
                    {this.state.model.id > 0 && this.state.isMyProfile && <h1>
                        Mój profil
                    </h1>}
                    <div className="clear"></div>
                    <div className="space"></div>
                    {!this.state.isMyProfile &&
                        <Link to={"/users"} className="bread"><i className="back"></i>&nbsp;Lista użytkowników</Link>
                    }
                    <Container component="main">
                        <div className="center-form">
                            <form validate="true" autoComplete="off" onSubmit={(event) => this.save(event)}>
                                <Grid container spacing={3}>
                                    <div className="validation-error">{this.state.validationMessage}</div>
                                    <div className="clear"></div>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <TextField required label="Imię" fullWidth value={this.state.model.name} onChange={(text) => { let model = { ...this.state.model }; model.name = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <TextField required label="Nazwisko" fullWidth value={this.state.model.surname} onChange={(text) => { let model = { ...this.state.model }; model.surname = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <TextField required label="Adres e-mail" type="email" fullWidth value={this.state.model.email} onChange={(text) => { let model = { ...this.state.model }; model.email = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <a className="blue-font left">Dodaj avatar</a>
                                        <FileUpload type="user" modelFile={this}></FileUpload>
                                        {this.state.model.src != null && <img className="avatar-form" src={this.state.model.src} />

                                        }
                                    </Grid>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel htmlFor="outlined-age-native-simple">Płeć</InputLabel>
                                            <Select native label="Płęć" value={this.state.model.sex} onChange={(text) => { let model = { ...this.state.model }; model.sex = parseInt(text.target.value); this.setState({ model: model }) }} inputProps={{ name: 'sex', id: 'outlined-sex-native-simple' }} >
                                                <option value={1}>Mężczyzna</option>
                                                <option value={2}>Kobieta</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                            <DatePicker  label="Data urodzenia" format="dd-MM-yyyy" maxDate={this.state.maxDate} value={this.state.model.birthDate} onChange={(text) => { let model = { ...this.state.model }; model.birthDate = text; this.setState({ model: model }) }}  />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <TextField label="Kod pocztowy" inputProps={{ maxLength: 6 }} fullWidth value={this.state.model.postalCode} onChange={(text) => { let model = { ...this.state.model }; model.postalCode = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <TextField label="Numer telefonu" fullWidth value={this.state.model.phoneNumber} onChange={(text) => { let model = { ...this.state.model }; model.phoneNumber = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <div className="space"></div>
                                    <Grid item xs={6} item>
                                        <TextField label="Numer prawa wykonywania zawodu" fullWidth value={this.state.model.position} onChange={(text) => { let model = { ...this.state.model }; model.position = text.target.value; this.setState({ model: model }) }} />
                                    </Grid>
                                    <Grid item xs={6} item>
                                        <Checkbox label="Newsletter" checked={this.state.model.isNewsletter} onChange={(text) => { let model = { ...this.state.model }; model.isNewsletter = text.target.checked; this.setState({ model: model }) }} />
                                        Newsletter
                                    </Grid>
                                    {localStorage.getItem('role')=="0" &&
                                        <Grid item xs={6} item>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-status-native-simple">Status</InputLabel>
                                                <Select native label="Status" value={this.state.model.userStatus} onChange={(text) => { let model = { ...this.state.model }; model.userStatus = parseInt(text.target.value); this.setState({ model: model }) }} inputProps={{ name: 'status', id: 'outlined-status-native-simple' }} >
                                                    <option value={0}>Aktywny</option>
                                                    <option value={1}>Nieaktywny</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    <div className="space"></div>
                                    {localStorage.getItem('role') == "0" &&
                                        <Grid item xs={6} item>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-status-native-simple">Rola</InputLabel>
                                                <Select native label="Rola" value={this.state.model.roleId} onChange={(text) => { let model = { ...this.state.model }; model.roleId = parseInt(text.target.value); this.setState({ model: model }) }} inputProps={{ name: 'role', id: 'outlined-status-native-simple' }} >
                                                    <option value={0}>Admin</option>
                                                    <option value={1}>Użytkownik</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    <div className="space"></div>
                                </Grid>                                
                                <div className="space"></div>
                                {this.state.model.id > 0 &&  this.state.roleId!=1 &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} item>
                                            <p>Id</p>
                                            <p>{this.state.model.id}</p>
                                        </Grid>
                                        <Grid item xs={6} item>
                                            <p>Data dołączenia</p>
                                            <p>{this.state.model.creationDate}</p>
                                        </Grid>
                                        <Grid item xs={6} item>
                                            <p>Zakończone kursy</p>
                                        <p><Link className="show" to={"/courses/1/" + this.state.model.id}></Link><Link className="blue-font left" to={"/courses/1/" + this.state.model.id}>&nbsp;Zobacz</Link></p>
                                        </Grid>
                                        <Grid item xs={6} item>
                                            <p>Lista rozpoczętych kursów</p>
                                        <p><Link className="show" to={"/courses/2/" + this.state.model.id}></Link><Link className="blue-font left" to={"/courses/2/" + this.state.model.id}>&nbsp;Zobacz</Link></p>
                                        </Grid>
                                    </Grid>
                                }
                                <div className="space"></div>
                                <div className="center">
                                    <Button type="submit" color="primary">
                                        Zapisz
                                    </Button>
                                    {this.state.model.id > 0 && this.state.isMyProfile && <h1>
                                        <Button type="button" color="primary" onClick={this.delete}>
                                            Usuń konto
                                        </Button>
                                    </h1>}
                                </div>
                            </form>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        )
    }
}

export default UserAddComponent;