import React from 'react';
import axios from 'axios';

const config = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
}

class CourseService extends React.Component {
    
    query = async (model) => {
        try {
            const { data } = await axios.post('api/course/query', model, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    remove = async (id) => {
        try {
            const { data } = await axios.post('api/course/remove', { id: id }, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    get = async (id, isTrue) => {
        try {
            const { data } = await axios.get('api/course/get?id=' + id+'&isTrue='+isTrue, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    getSimple = async (id, isTrue) => {
        try {
            const { data } = await axios.get('api/course/getSimple?id=' + id + '&isTrue=' + isTrue, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    getSimpleByCode = async (id, code) => {
        try {
            const { data } = await axios.get('api/course/getSimpleByCode?id=' + id+'&code='+code, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    checkNIP = (nip) => {
        if (nip == "")
            return true;

        if (nip.length == 10) {
            var newP = nip.substring(0, 10);
            var str = newP[0];
            var c1 = parseInt(str) * 6;
            str = newP[1];
            var c2 = parseInt(str) * 5;
            str = newP[2];
            var c3 = parseInt(str) * 7;
            str = newP[3];
            var c4 = parseInt(str) * 2;
            str = newP[4];
            var c5 = parseInt(str) * 3;
            str = newP[5];
            var c6 = parseInt(str) * 4;
            str = newP[6];
            var c7 = parseInt(str) * 5;
            str = newP[7];
            var c8 = parseInt(str) * 6;
            str = newP[8];
            var c9 = parseInt(str) * 7;

            var sum = c1 + c2 + c3 + c4 + c5 + c6 + c7 + c8 + c9;

            var rest = sum % 11;

            str = nip[9];

            if ((rest) == parseInt(str)) {
                return true;
            }

        }
        
        return false;
    }

    buyByCode = async (code, isZero, courseId, companyName, nip, firstName, lastName, address, houseNumber, flatNumber, postalCode, city, price) => {
        try {
            const { data } = await axios.post('api/course/buyByCode', { code: code, isZero: isZero, courseId: courseId, name: companyName, nip: nip, firstName: firstName, lastName: lastName, address: address, houseNumber: houseNumber, flatNumber: flatNumber, postalCode: postalCode, city: city, price:price }, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    getForUser = async (id, isTrue, idUser) => {
        try {
            const { data } = await axios.get('api/course/get?id=' + id + '&isTrue=' + isTrue+'&idUser='+idUser, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    save = async (model) => {
        try {

            const { data } = await axios.post('api/course/save', model, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    createAccess = async (model) => {
        try {

            const { data } = await axios.post('api/course/createAccess', model, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }

    saveAnswer = async (model) => {
        try {

            const { data } = await axios.post('api/course/saveAnswer', model, config);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }
}

export default new CourseService();