import React from 'react';
import Grid from '@material-ui/core/Grid';
import CourseService from './courseService';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import plLocale from "date-fns/locale/pl";
import LocalizedUtils from './../../localizedUtils';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Preloader from './../partials/preloader';

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Nazwa kursu' },
    { id: 'userProfileName', numeric: false, disablePadding: false, label: 'Dodane przez' },
    { id: 'quantity', numeric: false, disablePadding: false, label: 'Liczba kursantów' },
    { id: 'percentPass', numeric: false, disablePadding: false, label: 'Wymagany % ukończenia' },
    { id: 'price', numeric: false, disablePadding: false, label: 'Cena' },
    { id: 'action', numeric: false, disablePadding: false, disableSorting: true, label: 'Akcje' },
];

class CoursesComponent extends React.Component {

    constructor(props) {
        super();

        this.state = {
            order: 'asc',
            orderBy: '-id',
            selected: [],
            page: 1,
            dense: false,
            rowsPerPage: 10,
            search: '',
            startDateFrom: null,
            startDateTo: null,
            endDateFrom: null,
            endDateTo: null,
            userProfileName: null,
            quantityFrom: null,
            quantityTo: null,
            percentPassFrom: null,
            percentPassTo: null,
            priceFrom: null,
            priceTo: null,
            rows: [],
            id: parseInt(props.match.params.id),
            size: 0,
            userProfileId: parseInt(props.match.params.userProfileId)
        };

        this.setState({ emptyRows: this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage), isLoading: false })
        this.refreshItems();
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({ order: 'asc' });
        this.setState({ orderBy: property });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.rows.map((n) => n.id);
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, name) => {
        const selectedIndex = this.state.selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    refreshItems = async () => {
        let certs = await CourseService.query({ order: (this.state.order == 'desc' ? '-' : '') + this.state.orderBy, limit: 10, start: (this.state.page - 1) * 10, end: (this.state.page - 1) * 10 + 10, page: this.state.page, search: this.state.search, name: this.state.name, startDateFrom: this.state.startDateFrom, startDateTo: this.state.startDateTo, endDateFrom: this.state.endDateFrom, endDateTo: this.state.endDateTo, userProfileId: this.state.userProfileId, id: this.state.id, userProfileName: this.state.userProfileName, quantityFrom: this.state.quantityFrom, quantityTo: this.state.QuantityTo, priceFrom: this.state.priceFrom, priceTo: this.state.priceTo, percentPassFrom: this.state.percentPassFrom, percentPassTo: this.state.percentPassTo });
        this.setState({ rows: certs.items, size: certs.length, isLoading: true  });

    }

    handleChangePage = (event, newPage) => {
        this.state.page = newPage + 1;
        this.setState({ page: newPage + 1 });
        this.refreshItems();
    };

    onSelectAllClick = () => {
        let selected = [];
        for (let i = 0; i < this.state.rows.length; i++) {
            selected.push(this.state.rows[i].id);
        }
        this.setState(selected);
    };

    createSortHandler = (columnId) => {
        if (columnId != 'action') {
            this.state.order = columnId == this.state.orderBy && this.state.order != 'desc' ? 'desc' : 'asc';
            this.state.orderBy = columnId;
            this.setState({ orderBy: columnId });
            this.refreshItems();
        }
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };

    remove = async (id) => {
        if (window.confirm('Czy na pewno chcesz usunąć kurs?')) {
            await CourseService.remove(id);
            this.refreshItems();
        }
    };

    showFilter = async () => {
        this.setState({ isFilter: !this.state.isFilter })
    };

    removeCourses = async () => {
        if (window.confirm('Czy na pewno chcesz usunąć kursy?')) {
            for (let i = 0; i < this.state.selected.length; i++) {
                await CourseService.remove(this.state.selected[i]);
            }
            this.refreshItems();
        }
    };

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    clearFilter = () => {
        this.setState({
            name: '',
            startDateFrom: null,
            startDateTo: null,
            endDateFrom: null,
            endDateTo: null,
            userProfileName: null,
            quantityFrom: null,
            quantityTo: null,
            percentPassFrom: null,
            percentPassTo: null,
            priceFrom: null,
            priceTo: null,
        });
        this.state.name = '';
        this.state.startDateFrom = null;
        this.state.startDateTo = null;
        this.state.endDateFrom = null;
        this.state.endDateTo = null;
        this.state.userProfileName= null;
        this.state.quantityFrom= null;
        this.state.quantityTo= null;
        this.state.percentPassFrom= null;
        this.state.percentPassTo= null;
        this.state.priceFrom= null;
        this.state.priceTo= null;
        this.refreshItems();
    }

    render() {
        return (
            <div>
            {!this.state.isLoading && <Preloader /> }
                {this.state.isLoading &&
                    <Grid container>
                        <Grid item xs={12} className="wrapper">
                            <div className="border-bottom">
                                <Grid container spacing={3}>
                                    <Grid item xs={2} item>
                                        <h1 className="no-border">
                                            Lista kursów
                                        </h1>
                                    </Grid>
                                    <Grid item xs={8} item className="filter">
                                        <Link className="blue-font2 add-user" to="/courseAdd/0"><i className="playlist"></i>&nbsp;Dodaj nowy kurs</Link>
                                        <a className="gray-font2 not-mobile">Akcje grupowe: </a>
                                        <a className="blue-font2 not-mobile" onClick={(event) => this.removeCourses()}><i className="remove"></i>&nbsp;Usuń</a>
                                        <a className="gray-font2 not-mobile">Filtry: </a>
                                        <a className="blue-font2 not-mobile"><i className="search"></i>&nbsp;<TextField label="Szukaj" className="searchInput" onChange={(text) => { this.state.search = text.target.value; this.refreshItems(); }} /></a>
                                    </Grid>
                                    <Grid item xs={2} item>
                                        <a className="blue-font2 float-right not-mobile" onClick={(event) => this.showFilter()}><i className="filter-icon"></i>&nbsp;Pokaż wszystkie filtry</a>
                                    </Grid>
                                </Grid>
                                {this.state.isFilter &&
                                    <div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="Nazwa" value={this.state.name} onChange={(text) => { this.setState({ name: text.target.value }); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                                    <DatePicker label="Data rozpoczęcia" value={this.state.startDateFrom} format="dd-MM-yyyy" fullWidth onChange={(text) => { this.state.startDateFrom = text; this.refreshItems(); }} />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="Liczba kursantów od" type="number" value={this.state.quantityFrom} onChange={(text) => { this.setState({ quantityFrom: parseInt(text.target.value) }); this.state.quantityFrom = parseInt(text.target.value); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="Cena od" type="number" value={this.state.priceFrom} onChange={(text) => { this.setState({ priceFrom: parseInt(text.target.value) }); this.state.priceFrom = parseInt(text.target.value); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="% ukończenia od" type="number" value={this.state.percentPassFrom} onChange={(text) => { this.setState({ percentPassFrom: parseInt(text.target.value) }); this.state.percentPassFrom = parseInt(text.target.value); this.refreshItems(); }} />
                                            </Grid>
                                        </Grid>
                                        <div className="space"></div>
                                        <br />
                                        <Grid container spacing={3}>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="Dodane przez" value={this.state.userProfileName} onChange={(text) => { this.setState({ userProfileName: text.target.value }); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                                                    <DatePicker label="do" format="dd-MM-yyyy" value={this.state.startDateTo} fullWidth onChange={(text) => { this.state.startDateTo = text; this.refreshItems(); }} />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="do" type="number" value={this.state.quantityTo} onChange={(text) => { this.setState({ quantityTo: parseInt(text.target.value) }); this.state.quantityTo = parseInt(text.target.value); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="do" type="number" value={this.state.priceTo} onChange={(text) => { this.setState({ priceTo: parseInt(text.target.value) });; this.state.priceTo = parseInt(text.target.value); this.refreshItems(); }} />
                                            </Grid>
                                            <Grid item xs={2} item className="filter">
                                                <TextField label="do" type="number" value={this.state.percentPassTo} onChange={(text) => { this.setState({ percentPassTo: parseInt(text.target.value) }); this.state.percentPassTo = parseInt(text.target.value); this.refreshItems(); }} />
                                            </Grid>
                                        </Grid>
                                        <div className="space"></div>
                                        <br />
                                        <Grid container spacing={3}>
                                            <Grid item xs={2} item className="filter">
                                                <a onClick={(event) => this.clearFilter()} className="blue-font"><i className="block"></i>&nbsp;Wyczyść filtr</a>
                                            </Grid>
                                        </Grid>
                                        <div className="space"></div>
                                        <br />
                                        <br />
                                    </div>
                                }
                            </div>
                            <div className="space"></div>
                            <div>
                                <Paper>
                                    <TableContainer>
                                        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                        indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.rows.length}
                                                        checked={this.state.rows.length > 0 && this.state.selected.length === this.state.rows.length}
                                                            onChange={this.handleSelectAllClick}
                                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                                        />
                                                    </TableCell>
                                                    {headCells.map((headCell) => (
                                                        <TableCell
                                                            key={headCell.id}
                                                            align={headCell.numeric ? 'center' : 'left'}
                                                            padding={headCell.disablePadding ? 'none' : 'default'}
                                                            sortDirection={this.state.order}
                                                            onClick={(event) => this.createSortHandler(headCell.id)}
                                                        >
                                                            {!headCell.disableSorting && <TableSortLabel
                                                                active={this.state.orderBy === headCell.id}
                                                                direction={this.state.order}
                                                            >
                                                                {headCell.label}
                                                            </TableSortLabel>}
                                                            {headCell.disableSorting &&
                                                                <span>{headCell.label}</span>}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.rows.map((row, index) => {
                                                    const isItemSelected = this.isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox" onClick={(event) => this.handleClick(event, row.id)}>
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.userProfileName}</TableCell>
                                                            <TableCell align="left">{row.quantity}</TableCell>
                                                            <TableCell align="left">{row.percentPass}</TableCell>
                                                            <TableCell align="left">{row.price}</TableCell>
                                                            <TableCell align="left" className="actionColumn"><Link className="show" to={"/courseShow/" + row.id}></Link>&nbsp;&nbsp;&nbsp;<Link className="create" to={"/courseAdd/" + row.id}></Link>&nbsp;&nbsp;&nbsp;<i className="remove" onClick={(event) => this.remove(row.id)}></i>&nbsp;</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {this.state.emptyRows > 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        count={this.state.size}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page - 1}
                                        onChangePage={this.handleChangePage}
                                    />
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

export default CoursesComponent;